import { Entity } from 'types/inspection-types/Entity';
import { Model } from 'types/nn-types/Model';
import { EntityType } from 'types/inspection-types/EntityType';

export interface Group extends Entity {
    models: Model[];
    groupIdx: number;
}

export const GROUP_INSTANCE: Group = {
    id: '00000000-0000-0000-0000-000000000000',
    groupIdx: -1,
    type: EntityType.TREE_OF_MODELS,
    name: '',
    models: [],
    interestingness: [],
};

// Type-guard for the ModelGraphNode type
export function isGroup(entity: unknown): entity is Group {
    let result = true;

    Object.keys(GROUP_INSTANCE).forEach((key: string) => {
        result = result && (entity as any)[key] !== undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    return result;
}
