export class Point2D {
    constructor(public x: number, public y: number) {}

    public add(point: Point2D): Point2D {
        return new Point2D(this.x + point.x, this.y + point.y);
    }

    public subtract(point: Point2D): Point2D {
        return new Point2D(this.x - point.x, this.y - point.y);
    }

    public rotate(angle: number, center: Point2D): Point2D {
        if (angle === 0) return this.clone();
        angle = (angle * Math.PI) / 180;
        let point = center ? this.subtract(center) : this;
        const sin = Math.sin(angle),
            cos = Math.cos(angle);
        point = new Point2D(point.x * cos - point.y * sin, point.x * sin + point.y * cos);
        return center ? point.add(center) : point;
    }

    public scale(scale: number): Point2D {
        return new Point2D(this.x * scale, this.y * scale);
    }

    public clone(): Point2D {
        return new Point2D(this.x, this.y);
    }
}
