import { scaleLinear } from '@visx/scale';
import _ from 'lodash';
import { DataRange, WidgetDataEntity, WidgetDefinition } from 'types/inspection-types/WidgetDefinition';
import { isNumber } from 'types/inspection-types/DataArray';
import { AxisScale } from '@visx/axis';

/**
 * Returns a linear scale for the x-axis, using the "step" attribute as values.
 * @param entities a list of data entities, i.e., for each entity, there is one 2-D table of values
 * @param visXMax horizontal size (width) of the visualization
 * @param entityRanges if this parameter is set, it overrides the value ranges extracted from the entities
 */
export function getXScale(entities: WidgetDataEntity[], visXMax: number, entityRanges?: Record<string, DataRange>) {
    if (entityRanges) {
        // If entityRanges are given, use them as the scale extents
        return scaleLinear({
            domain: [entityRanges['step'].min, entityRanges['step'].max],
            range: [0, visXMax],
        });
    } else {
        // If entityRanges is omitted, calculate ranges from data rows
        const allSteps = entities.reduce((acc, e) => {
            return [...acc, ...e.data.map((d) => d['step'] as number)];
        }, [] as number[]);

        return scaleLinear({
            domain: [Math.min(...allSteps), Math.max(...allSteps)],
            range: [0, visXMax],
        });
    }
}

/**
 * Returns a linear scale for the y-axis, using the attributes in targetAttributes.
 * @param entities a list of data entities, i.e., for each entity, there is one 2-D table of values
 * @param targetAttributes the subset of attributes this scale should consider
 * @param scalingFnTemplate a creator function for the scale
 * @param visYMax vertical size (height) of the visualization
 * @param entityRanges if this parameter is set, it overrides the value ranges extracted from the entities
 */
export function getYScale(
    entities: WidgetDataEntity[],
    targetAttributes: string[],
    scalingFnTemplate: WidgetDefinition['scaleCreatorFn'],
    visYMax: number,
    entityRanges?: Record<string, DataRange>
): AxisScale<number> {
    if (entityRanges) {
        // If entityRanges are given, use them as the scale extents
        return scalingFnTemplate({
            domain: [
                Math.min(...(targetAttributes.map((a) => entityRanges[a].min) ?? 0)),
                Math.max(...(targetAttributes.map((a) => entityRanges[a].max) ?? 0)),
            ],
            range: [visYMax, 0],
        });
    } else {
        // If entityRanges is omitted, calculate ranges from data rows
        const allValues = entities.reduce((acc, e) => {
            return [
                ...acc,
                ..._.flatten(
                    e.data.map((d) => {
                        return targetAttributes
                            .map((attr) => {
                                // If a certain datapoint does not have the attribute, assign null and filter later
                                return isNumber(d[attr]) ? (d[attr] as number) : null;
                            })
                            .filter((v) => v !== null) as number[];
                    })
                ),
            ];
        }, [] as number[]);

        return scalingFnTemplate({
            domain: [Math.min(...allValues), Math.max(...allValues)],
            range: [visYMax, 0],
        });
    }
}
