import React, { FunctionComponent } from 'react';
import { WidgetProps } from '../Widget';
import _ from 'lodash';
import { scaleLinear } from '@visx/scale';
import { InnerVisContainer, OuterVisContainer } from './StyledContainers';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { DataRow, DataValue } from 'types/inspection-types/DataArray';
import { AxisBottom } from '@visx/axis';
import { getNumberFormatter } from 'tools/helpers';

const DEFAULT_MARGIN = { top: 10, right: 10, bottom: 30, left: 10 };

/**
 * Gets the value of an attribute across all steps and all rows.
 * @param stepwiseHistograms an array of steps with each element containing an array of DataRows (i.e., a DataTable)
 * @param attribute the key of the desired attribute
 * @return a flattened array of all values of the desired attribute
 */
const valuesAccessor = (stepwiseHistograms: DataRow[][], attribute: string): DataValue[] =>
    _.flatten(stepwiseHistograms.map((step) => step.map((row) => row[attribute])));

const MultiHistogramWidget: FunctionComponent<WidgetProps> = ({
    widgetDefinition,
    margin = DEFAULT_MARGIN,
}: WidgetProps) => {
    const entity = widgetDefinition.dataEntities[0];
    const stepwiseHistograms = Object.values(_.groupBy(entity.data, 'step')).sort(
        (g1, g2) => (g1[0]['step'] as number) - (g2[0]['step'] as number)
    );

    // console.log('Histogram data:', stepwiseHistograms);

    const maxCount = Math.max(...(valuesAccessor(stepwiseHistograms, 'count') as number[]));
    const minStartBoundary = Math.min(...(valuesAccessor(stepwiseHistograms, 'start') as number[]));
    const maxEndBoundary = Math.max(...(valuesAccessor(stepwiseHistograms, 'end') as number[]));

    const xLength = stepwiseHistograms[0].length;
    const yLength = stepwiseHistograms.length;

    const scaleOpacity = scaleLinear<number>({
        range: [0.1, 1],
        domain: [0, maxCount],
    });

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <ParentSize debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => {
                            const xExtend = visWidth - margin.left - margin.right;
                            const yExtend = visHeight - margin.top - margin.bottom;

                            const scaleX = scaleLinear({
                                domain: [minStartBoundary, maxEndBoundary],
                                range: [0, xExtend],
                            });

                            const scaleY = scaleLinear({
                                domain: [0, yLength],
                                range: [0, yExtend],
                            });

                            const blockWidth = xExtend / xLength;
                            const blockHeight = yExtend / yLength;

                            return (
                                <svg width={visWidth} height={visHeight} style={{ background: '#fff' }}>
                                    <Group top={margin.top} left={margin.left}>
                                        <AxisBottom tickFormat={getNumberFormatter(3)} top={yExtend} scale={scaleX} />
                                        {stepwiseHistograms.map((histogram, rowIdx) => {
                                            const step = histogram[0]['step'];
                                            const yPos = scaleY(rowIdx) ?? 0;

                                            return (
                                                <Group key={`heatmap-row-${rowIdx}`}>
                                                    {histogram.map((dataRow, colIdx) => {
                                                        const count = dataRow['count'] as number;
                                                        const startBoundary = dataRow['start'] as number;
                                                        const endBoundary = dataRow['end'] as number;

                                                        const xLeft = scaleX(startBoundary) ?? 0;
                                                        const xRight = scaleX(endBoundary) ?? 0;

                                                        return (
                                                            <rect
                                                                key={`heatmap-rect-${rowIdx}-${colIdx}`}
                                                                width={xRight - xLeft}
                                                                height={blockHeight}
                                                                x={xLeft}
                                                                y={yPos}
                                                                fill={'red'}
                                                                fillOpacity={scaleOpacity(count)}
                                                            />
                                                        );
                                                    })}
                                                    <text
                                                        x={blockWidth / 2}
                                                        y={yPos + blockHeight / 2}
                                                        style={{ dominantBaseline: 'middle' }}
                                                    >
                                                        {step as number}
                                                    </text>
                                                </Group>
                                            );
                                        })}
                                    </Group>
                                </svg>
                            );
                        }}
                    </ParentSize>
                </InnerVisContainer>
            </OuterVisContainer>
        </>
    );
};

export default React.memo(MultiHistogramWidget);
