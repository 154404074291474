import React, { useCallback, useEffect, useMemo } from 'react';
import WidgetContext from 'App/WidgetContext';
import {
    PromisedWidgetDefinition,
    WidgetDefinition,
    WidgetDefinitionWithId,
} from 'types/inspection-types/WidgetDefinition';
import * as uuid from 'uuid';
import { produce } from 'immer';
import _ from 'lodash';
import { LevelOfAbstraction, LevelsOfAbstraction, LofAFriendlyNames } from 'types/inspection-types/LevelOfAbstraction';
import { Subject } from 'rxjs';
import ToolContext from './ToolContext';
import { WidgetTable, WidgetTableRow } from 'types/inspection-types/WidgetTable';

type WidgetRecord = Record<string, WidgetDefinition>;
type WidgetGroupRecord = Record<string, WidgetRecord>;

export interface WidgetIdentifier {
    lofa: LevelOfAbstraction;
    groupId: string;
    widgetId: string;
}

// Holds the rows of the widget table, each containing groups (= table cells) of widgets on one LofA.
const INITIAL_WIDGET_TABLE_ROWS: Array<WidgetGroupRecord> = _.range(Math.max(...LevelsOfAbstraction) + 1).map(
    (_) => ({})
);

/**
 * Searches for the widget with the given ID in the widget table and returns the information needed to index it.
 * @param widgetId
 * @param widgetTable
 */
const getRowIndexAndGroupId = (
    widgetId: string,
    widgetTable: WidgetGroupRecord[]
): [LevelOfAbstraction, string] | null => {
    let result = null;

    widgetTable.forEach((row, rowId) => {
        Object.entries(row).forEach(([groupId, group]) => {
            if (group.hasOwnProperty(widgetId)) {
                result = [rowId, groupId];
            }
        });
    });

    return result;
};

/**
 * Searches for the group with the given ID in the widget table and returns the information needed to index it.
 * @param groupId
 * @param widgetTable
 */
const getRowIndex = (groupId: string, widgetTable: WidgetGroupRecord[]): LevelOfAbstraction | null => {
    let result = null;

    widgetTable.forEach((row, rowId) => {
        if (groupId in row) {
            result = rowId;
        }
    });

    return result;
};

/**
 * Converts the internal, array-based representation of widget table rows into a more readable object representation,
 * which can then be used, e.g., by the widget panel.
 * @param widgetTableRows the internal representation of the widget table
 */
const formatWidgetTable = (widgetTableRows: WidgetGroupRecord[]): WidgetTable => {
    const kvPairs: [LevelOfAbstraction, WidgetTableRow][] = LevelsOfAbstraction.map((lofa) => {
        const wgr = widgetTableRows[lofa];

        return [
            lofa,
            {
                rowName: `L${lofa} - ${LofAFriendlyNames[lofa]}`,
                widgetGroups: Object.entries(wgr).map(([gId, g]) => ({
                    groupId: gId,
                    widgetDefinitions: Object.entries(g).map(([wId, w]) => ({
                        widgetId: wId,
                        ...w,
                    })),
                })),
            },
        ];
    });

    return Object.fromEntries(kvPairs) as WidgetTable;
};

const WidgetContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [widgetTableRows, setWidgetTableRows] = React.useState<WidgetGroupRecord[]>(INITIAL_WIDGET_TABLE_ROWS);
    const [onWidgetAddSubject] = React.useState<Subject<WidgetIdentifier>>(new Subject<WidgetIdentifier>());
    const { unsetActiveTool } = React.useContext(ToolContext);

    const useOnWidgetAdded = (cb: (widgetIdentifier: WidgetIdentifier) => void) => {
        const subscribe = () => {
            const subscription = onWidgetAddSubject.subscribe(cb);

            return () => {
                subscription.unsubscribe();
            };
        };

        useEffect(subscribe, [cb]);
    };

    const updateWidget = (widgetId: string, newWd: WidgetDefinition): void => {
        setWidgetTableRows((prevState) =>
            produce(prevState, (draftState) => {
                const rowIndexAndGroupId = getRowIndexAndGroupId(widgetId, prevState);

                if (rowIndexAndGroupId) {
                    const [rowIndex, groupId] = rowIndexAndGroupId;
                    draftState[rowIndex][groupId][widgetId] = newWd;
                }
            })
        );
    };

    const addWidget = (wd: PromisedWidgetDefinition, lofa: LevelOfAbstraction): string => {
        const newWidgetId = uuid.v4();

        setWidgetTableRows((prevState) =>
            produce(prevState, (draftState) => {
                // Since promises might not be resolved yet, create an empty array as the data entities.
                // This case is detected by widget logic and the loading widget is rendered.
                const preliminaryWidgetDefinition: WidgetDefinition = {
                    ...wd,
                    dataEntities: wd.entities.map((pe) => ({ ...pe, data: [] })),
                };

                // Create a new group and widget ID for this widget
                const newGroupId = uuid.v4();

                // Add the new group and the widget to draft state
                draftState[lofa][newGroupId] = {
                    [newWidgetId]: preliminaryWidgetDefinition,
                };

                // Notify subscribers about widget adding
                onWidgetAddSubject.next({ lofa: lofa, groupId: newGroupId, widgetId: newWidgetId });

                // Now we need to wait for the data promises to resolve and replace the empty dummy widget
                // with the actual one.
                Promise.all(wd.entities.map((pe) => pe.data)).then((entityDataArray) => {
                    const resolvedEntities = entityDataArray.map((resolvedData, idx) => ({
                        ...wd.entities[idx],
                        data: resolvedData,
                    }));

                    const resolvedWidgetDefinition: WidgetDefinition = {
                        ...wd,
                        dataEntities: resolvedEntities,
                        ready: true,
                    };

                    updateWidget(newWidgetId, resolvedWidgetDefinition);
                });
            })
        );

        unsetActiveTool();

        return newWidgetId;
    };

    const removeWidget = (widgetId: string) => {
        setWidgetTableRows((prevState) =>
            produce(prevState, (draftState) => {
                const rowIndexAndGroupId = getRowIndexAndGroupId(widgetId, prevState);

                if (rowIndexAndGroupId) {
                    const [rowIndex, groupId] = rowIndexAndGroupId;
                    delete draftState[rowIndex][groupId][widgetId];

                    if (Object.keys(draftState[rowIndex][groupId]).length === 0) {
                        delete draftState[rowIndex][groupId];
                    }
                }
            })
        );
    };

    const moveWidget = (widgetId: string, targetGroupId?: string) => {
        setWidgetTableRows((prevState) =>
            produce(prevState, (draftState) => {
                const sourceRowIndexAndGroupId = getRowIndexAndGroupId(widgetId, prevState);

                if (sourceRowIndexAndGroupId) {
                    const [sourceRowIndex, sourceGroupId] = sourceRowIndexAndGroupId;

                    let targetRowIndex: number | null = sourceRowIndex;

                    // If targetGroupId is undefined, create a new group in the current layer
                    if (targetGroupId === undefined) {
                        targetGroupId = uuid.v4();
                        draftState[targetRowIndex][targetGroupId] = {};
                    } else {
                        targetRowIndex = getRowIndex(targetGroupId, prevState);
                    }

                    if (sourceRowIndex === targetRowIndex) {
                        const wd = draftState[sourceRowIndex][sourceGroupId][widgetId];
                        delete draftState[sourceRowIndex][sourceGroupId][widgetId];
                        draftState[targetRowIndex][targetGroupId][widgetId] = wd;
                    }

                    // Remove source group if now empty
                    if (Object.keys(draftState[sourceRowIndex][sourceGroupId]).length === 0) {
                        delete draftState[sourceRowIndex][sourceGroupId];
                    }
                }
            })
        );
    };

    /**
     * Returns the widgets that are associated with the given entityId, regardless if they are pinned,
     * on which lofa they exist, or any other restrictions.
     * @param entityId
     */
    const getAssociatedWidgets = (entityId: string): WidgetDefinitionWithId[] => {
        const result: WidgetDefinitionWithId[] = [];

        widgetTableRows.forEach((row) => {
            // Iterate over all widget groups
            Object.values(row).forEach((group) => {
                // Iterate over all widgets in current group
                Object.entries(group).forEach(([wId, wd]) => {
                    if (wd.targetEntity.id === entityId) {
                        result.push({
                            ...wd,
                            widgetId: wId,
                        });
                    }
                });
            });
        });

        return result;
    };

    const widgetTableMemo = useMemo(() => formatWidgetTable(widgetTableRows), [widgetTableRows]);

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const addWidgetMemo = useCallback(addWidget, [onWidgetAddSubject, unsetActiveTool]);
    const removeWidgetMemo = useCallback(removeWidget, []);
    const moveWidgetMemo = useCallback(moveWidget, []);
    const getAssociatedWidgetsMemo = useCallback(getAssociatedWidgets, [widgetTableRows]);
    const useOnWidgetAddedMemo = useCallback(useOnWidgetAdded, [onWidgetAddSubject]);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            widgetTable: widgetTableMemo,
            addWidget: addWidgetMemo,
            removeWidget: removeWidgetMemo,
            moveWidget: moveWidgetMemo,
            getAssociatedWidgets: getAssociatedWidgetsMemo,
            useOnWidgetAdded: useOnWidgetAddedMemo,
        }),
        [
            addWidgetMemo,
            getAssociatedWidgetsMemo,
            moveWidgetMemo,
            removeWidgetMemo,
            useOnWidgetAddedMemo,
            widgetTableMemo,
        ]
    );

    return <WidgetContext.Provider value={providerValueMemo}>{children}</WidgetContext.Provider>;
};

export default WidgetContextProvider;
