import React, { FunctionComponent, useEffect, useState } from 'react';
import { ProvidedZoom } from '@visx/zoom/lib/types';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { ZoomState } from 'types/ZoomState';
import { BiMapAlt } from 'react-icons/bi';
import CardFlap, { Side } from 'App/CardFlap';
import { localPoint } from '@visx/event';
import Wrapper from 'App/InspectionPanel/Wrapper';
import { Card, Collapse } from 'react-bootstrap';

const DEFAULT_POSITION = {
    bottom: 40,
    width: 400,
    height: 300,
};

interface Props {
    contentsBoundingBox: UseMeasureRect | undefined;
    bottom?: number;
    width?: number;
    height?: number;
    zoom: ProvidedZoom<SVGSVGElement> & ZoomState;
    zoomString: string;
}

const Minimap: FunctionComponent<Props> = ({
    contentsBoundingBox,
    zoom,
    width = DEFAULT_POSITION.width,
    height = DEFAULT_POSITION.height,
    bottom = DEFAULT_POSITION.bottom,
}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState(false);
    const [minimapViewBox, setMinimapViewBox] = useState<string>();

    const inspectionContentsRef = React.useRef<SVGUseElement>(null);
    const viewportRef = React.useRef<SVGUseElement>(null);

    useEffect(() => {
        const margin = 10;

        const height = contentsBoundingBox?.height ?? 0;
        const width = contentsBoundingBox?.width ?? 0;
        const x = contentsBoundingBox?.x ?? 0;
        const y = contentsBoundingBox?.y ?? 0;

        setMinimapViewBox(`${x - margin} ${y - margin} ${width + 2 * margin} ${height + 2 * margin}`);
    }, [contentsBoundingBox]);

    const mouseHandler = (e: React.MouseEvent<SVGElement>) => {
        if (inspectionContentsRef.current && viewportRef.current) {
            if (e.type === 'click' || (e.type === 'mousemove' && isDragging)) {
                const localEventPoint = localPoint(inspectionContentsRef.current, e) || undefined;
                const { width: viewportWidth, height: viewportHeight } = viewportRef.current?.getBBox();
                const { transformMatrix } = zoom;

                zoom.setTranslate({
                    translateX: -(localEventPoint?.x ?? 0) * transformMatrix.scaleX + viewportWidth / 2,
                    translateY: -(localEventPoint?.y ?? 0) * transformMatrix.scaleY + viewportHeight / 2,
                });
            }
        }

        e.stopPropagation();
    };

    return (
        <Wrapper marginLeft={0} marginBottom={bottom}>
            <Collapse in={!isCollapsed}>
                <Card style={{ boxShadow: '0 0 10px var(--gray)' }}>
                    <svg
                        className={'no-select'}
                        height={height}
                        onMouseDown={(e) => {
                            setIsDragging(true);
                            e.stopPropagation();
                        }}
                        onMouseUp={(e) => {
                            setIsDragging(false);
                            e.stopPropagation();
                        }}
                        onMouseOut={(e) => {
                            setIsDragging(false);
                            e.stopPropagation();
                        }}
                        onClick={mouseHandler}
                        onMouseMove={mouseHandler}
                        style={{ padding: 5 }}
                        viewBox={minimapViewBox}
                        width={width}
                    >
                        {!isCollapsed && (
                            <g style={{ pointerEvents: 'none' }}>
                                <use href="#inspection-contents" ref={inspectionContentsRef} />
                                <use transform={zoom.toStringInvert()} href="#viewport" ref={viewportRef} />
                            </g>
                        )}
                    </svg>
                </Card>
            </Collapse>
            <CardFlap
                backgroundColor="white"
                icon={<BiMapAlt style={{ marginLeft: 10 }} />}
                isCardCollapsed={isCollapsed}
                setIsCardCollapsed={setIsCollapsed}
                side={Side.RIGHT}
            />
        </Wrapper>
    );
};

export default Minimap;
