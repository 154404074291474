import React, { useContext } from 'react';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';
import styled from 'styled-components';
import { greyStroke } from 'styles/colors';
import SelectionContext from 'App/SelectionContext';
import ToolContext from 'App/ToolContext';
import { darker, lightest, mid } from 'tools/colors';
import { useInterestingnessSVGFilter, useSVGFilter } from 'tools/hooks/useSVGFilter';
import InterestingnessContext from 'App/InterestingnessContext';
import ModelContext from 'App/ModelContext';
import ApplicabilityHint from 'App/InspectionPanel/ApplicabilityHint';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

const LayerBoundingRect = styled.rect<{ fill: string; selected: boolean }>`
    fill: ${(styles) => styles.fill};
    stroke: ${mid(greyStroke)};
    stroke-width: ${(styles) => (styles.selected ? 5 : 1)}px;
`;

interface Props {
    modelGraphNode: ModelGraphNode;
    width: number;
    height: number;
    hovered: boolean;
}

const LayerBoundingBox: React.FunctionComponent<Props> = ({ modelGraphNode, width, height, hovered }: Props) => {
    const { model } = useContext(ModelContext);
    const { activeTool } = useContext(ToolContext);
    const { isSelected } = useContext(SelectionContext);
    const { getInterestingness, getMaxInnerInterestingness } = useContext(InterestingnessContext);

    const [filterId, filterElement] = useSVGFilter(
        <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor={darker(model.preferences.baseColor)} />
    );

    useBrush<string | undefined>('entity-hovered', hovered ? modelGraphNode.id : undefined);
    const [isLinked] = useLink<string | undefined>('widget-hovered', (brushedEntityIds) => {
        return brushedEntityIds ? brushedEntityIds.includes(modelGraphNode.id) : false;
    });

    const selected = isSelected(modelGraphNode);

    const maxInterestingness = getMaxInnerInterestingness(model);
    const layerInterestingness = getInterestingness(modelGraphNode);
    let interestingness;
    if (maxInterestingness && layerInterestingness) {
        interestingness = layerInterestingness / maxInterestingness;
    }
    const [interestingnessFilterId, interestingnessFilterElement] = useInterestingnessSVGFilter(interestingness ?? 0);

    return (
        <g>
            {filterElement}
            {interestingnessFilterElement}
            {activeTool?.isApplicable(modelGraphNode.type) && <ApplicabilityHint x={width / 2} y={0} size={16} />}
            <LayerBoundingRect
                className={activeTool?.isApplicable(modelGraphNode.type) || isLinked ? 'clickable-svg-element' : ''}
                fill={lightest(model.preferences.baseColor)}
                filter={
                    isLinked ? `url(#${filterId})` : interestingness ? `url(#${interestingnessFilterId})` : undefined
                }
                selected={selected}
                rx={5}
                x={0}
                y={0}
                width={width}
                height={height}
            />
        </g>
    );
};

export default LayerBoundingBox;
