import React, { useCallback, useMemo } from 'react';
import NeuronSelectionContext from 'App/InspectionPanel/L1LayerUnitComponent/NeuronSelectionContext';
import { produce } from 'immer';

const NeuronSelectionContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedNeurons, setSelectedNeurons] = React.useState<string[]>([]);

    const toggleNeuronSelection = (nId: string) => {
        setSelectedNeurons((prevState) =>
            produce(prevState, (draftState) => {
                const selectedNeuronsSet = new Set(draftState);
                selectedNeuronsSet.has(nId) ? selectedNeuronsSet.delete(nId) : selectedNeuronsSet.add(nId);
                return [...selectedNeuronsSet];
            })
        );
    };

    const isNeuronSelected = (nId: string): boolean => {
        return selectedNeurons.includes(nId);
    };

    const resetNeuronSelection = () => {
        setSelectedNeurons([]);
    };

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const toggleNeuronSelectionMemo = useCallback(toggleNeuronSelection, []);
    const isNeuronSelectedMemo = useCallback(isNeuronSelected, [selectedNeurons]);
    const resetNeuronSelectionMemo = useCallback(resetNeuronSelection, []);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            selectedNeurons,
            toggleNeuronSelection: toggleNeuronSelectionMemo,
            isNeuronSelected: isNeuronSelectedMemo,
            resetNeuronSelection: resetNeuronSelectionMemo,
        }),
        [isNeuronSelectedMemo, resetNeuronSelectionMemo, selectedNeurons, toggleNeuronSelectionMemo]
    );

    return <NeuronSelectionContext.Provider value={providerValueMemo}>{children}</NeuronSelectionContext.Provider>;
};

export default NeuronSelectionContextProvider;
