import { curveBasis, line } from 'd3-shape';
import { Point2D } from 'types/Point2D';
import { isFlatShape, isModelGraphNode, Shape } from 'types/nn-types/ModelGraph';
import React from 'react';
import * as uuid from 'uuid';
import BoundingBox from 'types/BoundingBox';
import * as mathjs from 'mathjs';
import { Matrix } from 'mathjs';
import npyjs from 'npyjs';
import { NumberValue } from 'd3-scale';
import { isModel, Model } from 'types/nn-types/Model';
import { Entity } from 'types/inspection-types/Entity';
import { isLayerGraphNode } from 'types/nn-types/LayerGraph';
import getRandomName from 'tools/random-name/getRandomName';

export function positionsToPathString(positions: { x: number; y: number }[]): string {
    const path = line<{ x: number; y: number }>()
        .x((d) => d.x)
        .y((d) => d.y)
        .curve(curveBasis);
    // .curve(curveBundle.beta(1));

    const pathString = path(positions);

    return pathString !== null ? pathString : '';
}

export function reduceShapeToNumber(shape: Shape): number {
    return shape.reduce((acc: number, curr) => {
        const currReduced = curr !== null ? (isFlatShape(curr) ? reduceShapeToNumber(curr) : curr) : 1;
        return acc * (curr !== null ? currReduced : 1);
    }, 1);
}

export function reduceShapeToString(shape: Shape): string {
    return (
        '[' +
        shape.reduce((acc: string, curr, idx) => {
            const delim = idx === shape.length - 1 ? '' : ' x ';
            const currReduced = curr !== null ? (isFlatShape(curr) ? reduceShapeToString(curr) : curr) : 1;
            return acc.concat(curr !== null ? currReduced + delim : '');
        }, '') +
        ']'
    );
}

// Source: https://stackoverflow.com/a/926567
export function getFormattedDate(date: Date | number) {
    const dateCleaned: Date = typeof date === 'number' ? new Date(date) : date;

    const f = (n: number): string => {
        // Format integers to have at least two digits.
        return n < 10 ? `0${n}` : `${n}`;
    };

    return `${dateCleaned.getUTCFullYear()}-${f(dateCleaned.getUTCMonth() + 1)}-${f(dateCleaned.getUTCDate())}_${f(
        dateCleaned.getUTCHours()
    )}-${f(dateCleaned.getUTCMinutes())}-${f(dateCleaned.getUTCSeconds())}`;
}

export function svgToScreenPos(svgElement: SVGSVGElement, svgX: number, svgY: number) {
    const p = svgElement.createSVGPoint();
    p.x = svgX;
    p.y = svgY;

    return p.matrixTransform(svgElement.getScreenCTM() ?? undefined);
}

export function getFilterDef(color: string, opacity?: number): { filterElement: JSX.Element; filterId: string } {
    const filterId = uuid.v4();

    const localOpacity = opacity || 0;

    const filterElement = (
        <defs>
            <filter id={filterId}>
                <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor={color} floodOpacity={localOpacity} />
            </filter>
        </defs>
    );

    return { filterElement, filterId };
}

export function toSubscriptText(value: number | string) {
    const MAPPING: Record<string, string> = {
        a: '\u2090',
        e: '\u2091',
        i: '\u1d62',
        o: '\u2092',
        u: '\u1d64',

        h: '\u2095',
        j: '\u2c7c',
        k: '\u2096',
        l: '\u2097',
        m: '\u2098',
        n: '\u2099',
        p: '\u209a',
        r: '\u1d63',
        s: '\u209b',
        t: '\u209c',
        v: '\u1d65',
        x: '\u2093',

        '0': '\u2080',
        '1': '\u2081',
        '2': '\u2082',
        '3': '\u2083',
        '4': '\u2084',
        '5': '\u2085',
        '6': '\u2086',
        '7': '\u2087',
        '8': '\u2088',
        '9': '\u2089',

        '+': '\u208a',
        '-': '\u208b',
        '*': '\u204e',
        '=': '\u208c',

        '(': '\u208d',
        ')': '\u208e',

        '<': '\u02f1',
        '>': '\u02f2',
    };

    let str = value + '';
    Object.entries(MAPPING).forEach(([from, to]) => (str = str.replace(from, to)));
    return str;
}

export function matrixToBase64Image(matrix: mathjs.Matrix, scale = true): string {
    let localMatrix = matrix.clone();

    // If biggest value is <= 1.0, scale to [0, 255]
    if (scale) {
        localMatrix = mathjs.map(localMatrix, (v) => Math.round(v * 255));
    }

    // Clamp to [0, 255]
    localMatrix = mathjs.map(localMatrix, (v) => Math.max(0, Math.min(255, v)));

    let size = localMatrix.size();

    // Make sure that matrix has color channel
    if (size.length === 2) {
        localMatrix = mathjs.reshape(localMatrix, [...size, 1]);
        size = localMatrix.size();
    }

    // Make sure that matrix has 3 color channels
    if (size[2] === 1) {
        localMatrix = mathjs.concat(localMatrix, localMatrix, localMatrix, 2) as mathjs.Matrix;
        size = localMatrix.size();
    }

    // Make color channels RGBA
    size[2] = 4;
    const imageRawRGBA: mathjs.Matrix = mathjs.resize(localMatrix, size, 255);

    // Now make image from matrix
    const imageRawFlat = new Uint8ClampedArray(mathjs.flatten(imageRawRGBA).toArray() as number[]);
    const imageData = new ImageData(imageRawFlat, size[0], size[1]);

    const domCanvas = document.createElement('canvas');
    domCanvas.setAttribute('width', `${imageData.width}`);
    domCanvas.setAttribute('height', `${imageData.height}`);
    const domCanvas2DContext = domCanvas.getContext('2d');

    if (domCanvas2DContext) {
        domCanvas2DContext.putImageData(imageData, 0, 0);
        return domCanvas.toDataURL('image/png');
    }

    return '';
}

export function npyToMatrix(npyArray: npyjs.NumpyArray): mathjs.Matrix {
    const mjsMatrix: mathjs.Matrix = mathjs.matrix(Array.from<number>(npyArray.data));
    mathjs.reshape(mjsMatrix, npyArray.shape);
    return mjsMatrix;
}

// E.g., for a localMatrix of shape [2,4,4,3], gets following ranges:
// [0,1], [0,1,2,3], [0,1,2,3], [0,1,2]
export function getFullRangeForEachDim(matrix: mathjs.Matrix): Array<Matrix | number | number[]> {
    const shape = matrix.size();
    return shape.map((s) => {
        return mathjs.range(0, s);
    });
}

/**
 * Similar to matrix[dim,:,...]
 *                    ^-- index
 * @param matrix
 * @param dim
 * @param index
 */
export function extractDim(matrix: mathjs.Matrix, dim: number, index: number | number[]) {
    const ranges = getFullRangeForEachDim(matrix);
    ranges[dim] = index;
    const subset = mathjs.subset(matrix, mathjs.index(...ranges));

    // If only one index was given, remove the new singleton dimension
    if (!Array.isArray(index)) {
        const size = matrix.size();
        size.splice(dim, 1);
        mathjs.reshape(subset, size);
    }

    return subset;
}

export function matrixMoveLastDimToFront(matrix: mathjs.Matrix) {
    const result = [];

    const shape = mathjs.size(matrix) as number[];
    const ranges = getFullRangeForEachDim(matrix).slice(0, -1);

    for (let i = 0; i < shape[shape.length - 1]; i++) {
        result.push(mathjs.squeeze(mathjs.subset(matrix, mathjs.index(...ranges, i))));
    }

    return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayIntersection(arrayA: ArrayLike<any>, arrayB: ArrayLike<any>) {
    return [...new Set(Array.from(arrayA).filter((a) => Array.from(arrayB).includes(a)))];
}

export function isPointInBBox(point: Point2D, bbox: BoundingBox): boolean {
    if (point.x >= bbox.left && point.x <= bbox.left + bbox.width) {
        if (point.y >= bbox.top && point.y <= bbox.top + bbox.height) {
            return true;
        }
    }

    return false;
}

export function filterRecord<T extends string | number | symbol, U>(
    r: Record<T, U>,
    filterFn: (key: T, value: U) => boolean
): Record<T, U> {
    const result = {} as Record<T, U>;

    let key: T;
    for (key in r) {
        const value = r[key];

        if (filterFn(key, value)) {
            result[key] = value;
        }
    }

    return result;
}

export function stringJoinJSX(arr: string[], jsx: JSX.Element): JSX.Element {
    return arr.reduce((acc: JSX.Element, curr, idx) => {
        return (
            <>
                {acc}
                {curr}
                {idx < arr.length - 1 && React.cloneElement(jsx)}
            </>
        );
    }, <></>);
}

export function unitToSymbol(unit: string) {
    if (unit.toLowerCase() === 'bits') return 'b';
    if (unit.toLowerCase() === 'bytes') return 'B';
    if (unit.toLowerCase() === 'second') return 's';
    if (unit.toLowerCase() === 'minute') return 'mins';
    if (unit.toLowerCase() === 'hour') return 'h';

    return unit;
}

// Src: https://stackoverflow.com/a/9462382
export function getNumberFormatter(digits: number, prefix = '', postfix = '') {
    const si = [
        { value: 1, symbol: '' },
        { value: 1e3, symbol: 'k' },
        { value: 1e6, symbol: 'M' },
        { value: 1e9, symbol: 'G' },
        { value: 1e12, symbol: 'T' },
        { value: 1e15, symbol: 'P' },
        { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    return (num: number | NumberValue) => {
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num.valueOf() >= si[i].value) {
                break;
            }
        }
        return prefix + (num.valueOf() / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol + postfix;
    };
}

/**
 * Returns the highest training step for which a checkpoint is available.
 * @param model
 */
export const getLastCheckpointStep = (model: Model) => {
    return model.checkpointCatalog.checkpoints.map((chkpt) => chkpt.step).sort((s1, s2) => s2 - s1)[0];
};

/**
 * Check, whether a matrix has the correct shape to be interpreted as an image.
 * @param matrix
 */
export const isInterpretableAsImage = (matrix: Matrix) => {
    const matrixSize = matrix.size();

    if (matrixSize.length < 2 || matrixSize.length > 3) {
        // We assume an image to have either 2 (greyscale) or 3 (greyscale or color) dimension.
        return false;
    }

    if (matrixSize.length === 3) {
        // We have a potential color channel. It should have either depth 1 (greyscale) or 3 (color).
        if (!(matrixSize[2] === 1 || matrixSize[2] === 3)) {
            return false;
        }
    }

    // It seems that this matrix has the correct shape to be interpreted as an image.
    return true;
};

/**
 * Checks whether the command key (⌘) on macOS is pressed.
 *
 * Motivated by this answer on StackOverflow: https://stackoverflow.com/a/5500536/7061914.
 * The platform identifier for Intel- and M1-based Apple devices is still the same:
 *  https://stackoverflow.com/a/64958299/7061914.
 *
 * @param event The mouse event which may be associated with a key press of the command key on macOS.
 */
export const isCommandKeyPressedOnMacOS = (event: React.MouseEvent) => {
    const platform = window.navigator.platform;
    const macOSPlatform = 'MacIntel';

    return event.metaKey && platform === macOSPlatform;
};

export function bounds(array: Array<number>): { min: number; max: number } {
    return array.reduce(({ min, max }, item) => ({ min: Math.min(item, min), max: Math.max(item, max) }), {
        min: Infinity,
        max: -Infinity,
    });
}

export function getModelIdFromEntity(entity: Entity): string | undefined {
    if (isModel(entity)) {
        return entity.id;
    } else if (isModelGraphNode(entity)) {
        return entity.parentModelId;
    } else if (isLayerGraphNode(entity)) {
        return entity.parentModelId;
    } else {
        return undefined;
    }
}

export function deriveChildInnspectorHeader(parent: Model): string {
    const childName = getRandomName();

    // Create a new iNNspector header, containing the relevant information.
    const childHeader = `def get_innspector_metadata():\n  return {\n    "id": "${uuid.v4()}",\n    "name": "${
        parent.info.name
    }_child-${childName.toLowerCase()}",\n    "label": "${
        parent.info.label
    }, Child '${childName}'",\n    "parents": ["${parent.id}"]\n  }`;

    return childHeader;
}

export function closeEquals(a: number, b: number, epsilon = 0.0000001) {
    return Math.abs(a - b) < epsilon;
}
