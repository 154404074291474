import React, { useContext, useState } from 'react';
import FilterContext from 'App/FilterContext';
import getEnclosedEntityTypes from 'tools/get-enclosed-filter-types';
import {
    EntityType,
    getEntityCategory,
    getEntityTypeColor,
    getEntityTypeFriendlyName,
    getEntityTypeSymbol,
} from 'types/inspection-types/EntityType';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { CenteredCardBody, RotatedText, StyledCard } from 'App/SettingsPanel/styled';
import GroupsContext from 'App/GroupsContext';
import _ from 'lodash';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

interface EntityTypeWithName {
    entityType: EntityType;
    entityTypeName: string;
    entityTypeCategory: string;
}

interface Props {}

const FilterSettings: React.FunctionComponent<Props> = (props: Props) => {
    const { groups } = useContext(GroupsContext);
    const models = _.flatten(groups.map((g) => g.models));

    const { addFilter, clearFilter, filters } = React.useContext(FilterContext);

    const allTypesInTree: EntityTypeWithName[] = [
        ...new Set(
            models.reduce((acc, m) => {
                return [...acc, ...getEnclosedEntityTypes(m)];
            }, [] as EntityType[])
        ),
    ].map((t) => ({
        entityType: t,
        entityTypeName: getEntityTypeFriendlyName(t),
        entityTypeCategory: getEntityCategory(t),
    }));

    const typeCategories: string[] = [
        ...allTypesInTree.reduce((acc, t) => acc.add(t.entityTypeCategory), new Set<string>()),
    ];

    const typeGroups: Record<string, EntityTypeWithName[]> = {};
    typeCategories.forEach((tc) => (typeGroups[tc] = []));
    allTypesInTree.forEach((t) => typeGroups[t.entityTypeCategory].push(t));

    return (
        <>
            {Object.entries(typeGroups).map(([groupName, groupMembers], idx) => (
                <StyledCard key={idx}>
                    <RotatedText>{groupName}</RotatedText>
                    <CenteredCardBody>
                        <Form>
                            {groupMembers.map((t, idx) => {
                                const checked = filters.includes(t.entityType);
                                const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
                                    e.currentTarget.checked ? addFilter(t.entityType) : clearFilter(t.entityType);
                                };

                                return (
                                    <Form.Check
                                        checked={checked}
                                        key={idx}
                                        label={
                                            <FilterLabel
                                                checked={checked}
                                                entityType={t.entityType}
                                                entityTypeName={t.entityTypeName}
                                            />
                                        }
                                        type={'checkbox'}
                                        id={`checkbox-${groupName}-${t.entityType}`}
                                        onChange={onChangeHandler}
                                    />
                                );
                            })}
                        </Form>
                    </CenteredCardBody>
                </StyledCard>
            ))}
        </>
    );
};

const FilterLabel = ({
    checked,
    entityType,
    entityTypeName,
}: {
    checked: boolean;
    entityType: EntityType;
    entityTypeName: string;
}) => {
    const [hovered, setHovered] = useState<boolean>(false);

    useBrush<EntityType | undefined>('filter-hovered', hovered ? entityType : undefined);
    const [isLinked] = useLink<EntityType | undefined>('filter-badge-hovered', entityType);

    const badgeColor = getEntityTypeColor(entityType);
    const BadgeSymbol = getEntityTypeSymbol(entityType);

    return (
        <LabelWithCircle onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {(checked && hovered) || isLinked ? <b>{entityTypeName}</b> : entityTypeName}
            &nbsp;
            <BadgeSymbol style={{ color: badgeColor }} />
        </LabelWithCircle>
    );
};

const LabelWithCircle = styled.div`
    display: inline-block;
`;

export default FilterSettings;
