import { EntityType } from 'types/inspection-types/EntityType';
import { Entity } from 'types/inspection-types/Entity';
import { InterestingnessDescriptor } from 'types/inspection-types/InterestingnessDescriptor';
import * as uuid from 'uuid';

interface ILayerGraphNode extends Entity {
    parentModelId: string;
    parentModelGraphNodeId: string;
    description: string[];
}

export interface LayerGraphLink {
    source: string;
    target: string;
}

export interface LayerGraph {
    nodes: LayerGraphNode[];
    links: LayerGraphLink[];
}

// This is needed for runtime typechecks to enable iterating over interface keys (for type-guard)
export class LayerGraphNode implements ILayerGraphNode {
    public readonly id;

    constructor(
        public readonly name: string,
        public readonly parentModelId: string,
        public readonly parentModelGraphNodeId: string,
        public readonly description: string[],
        public readonly type: EntityType,
        public interestingness: InterestingnessDescriptor[] = []
    ) {
        this.id = uuid.v4();
    }

    public static fromRecord({
        name,
        type,
        parentModelId,
        parentModelGraphNodeId,
        description,
        interestingness,
    }: {
        name: string;
        type: EntityType;
        parentModelId: string;
        parentModelGraphNodeId: string;
        description: string[];
        interestingness?: InterestingnessDescriptor[];
    }) {
        return new LayerGraphNode(name, parentModelId, parentModelGraphNodeId, description, type, interestingness);
    }
}

const LAYER_GRAPH_NODE_INSTANCE = new LayerGraphNode('', '', '', [], EntityType.MISC, []);

// Type-guard for the LayerGraphNode type
export function isLayerGraphNode(entity: unknown): entity is LayerGraphNode {
    let result = true;

    Object.keys(LAYER_GRAPH_NODE_INSTANCE).forEach((key: string) => {
        result = result && (entity as any)[key] !== undefined; // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    return result;
}
