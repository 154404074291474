/* eslint-disable no-console */

import React, { useMemo } from 'react';
import { Model } from 'types/nn-types/Model';
import MainView from 'App/MainView';
import ToolContextProvider from 'App/ToolContextProvider';
import queryModels from 'App/query-models';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { interpolateModelColors } from 'tools/d3-scheme-model-color';
import { ordinalColorscale } from 'tools/colors';
import { constructModelGroups } from 'tools/construct-model-groups';
import GroupsContextProvider from 'App/GroupsContextProvider';
import { useSearchParam } from 'react-use';
import { stringArrayDecoder } from 'tools/json-decoders';
import { produce } from 'immer';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import CacheDashboard from 'App/CacheDashboard';
import { ToastContainer } from 'react-toastify';

const App: React.FunctionComponent = () => {
    const [modelDict, setModelDict] = React.useState<Record<string, Model>>({});
    const [expectedModels, setExpectedModels] = React.useState(0);

    // Filter model IDs based on 'modelIds' URL parameter.
    const modelsSearchParameter = useSearchParam('modelIds');

    const modelFilter = useMemo(() => {
        if (modelsSearchParameter) {
            const decodeResult = stringArrayDecoder.decode(JSON.parse(modelsSearchParameter));
            if (decodeResult.isOk()) {
                return decodeResult.value;
            }
        }
    }, [modelsSearchParameter]);

    React.useEffect(() => {
        /* ************************* */
        /* Query models from backend */
        /* ************************* */
        queryModels(modelFilter).then((modelPromiseArray) => {
            setExpectedModels(modelPromiseArray.length);

            modelPromiseArray.map((modelPromise) => {
                modelPromise.then((model) => {
                    setModelDict((prevState) =>
                        produce(prevState, (draftState) => {
                            draftState[model.id] = model;

                            // Create colorscale and assign color to each model
                            const modelIds = Object.keys(draftState).sort();
                            const modelColorScale = ordinalColorscale(modelIds, interpolateModelColors);
                            Object.entries(draftState).forEach(
                                ([mId, m]) => (m.preferences.baseColor = modelColorScale(mId))
                            );
                        })
                    );
                });
            });
        });
    }, [modelFilter]);

    // When all models are queried and added to modelDict, debug-print them to console
    React.useEffect(() => {
        if (expectedModels > 0) {
            if (Object.keys(modelDict).length === expectedModels) {
                console.groupCollapsed('Models');
                Object.entries(modelDict).forEach(([mId, m]) => console.log(mId, m));
                console.groupEnd();
            }
        }
    }, [expectedModels, modelDict]);

    const models = Object.values(modelDict);
    const groups = constructModelGroups(models);

    const router = createBrowserRouter([
        {
            element: (
                <>
                    <DndProvider backend={HTML5Backend}>
                        <ToolContextProvider>
                            {/*Toggle comment of below lines to enable iterative model rendering. Looks nicer, is slower.*/}
                            {/*{models.length > 0 && <MainView models={models} />}*/}
                            {models.length > 0 && models.length === expectedModels && (
                                <GroupsContextProvider groups={groups}>
                                    <MainView />
                                </GroupsContextProvider>
                            )}
                        </ToolContextProvider>
                    </DndProvider>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </>
            ),
            path: '/',
        },
        {
            element: <CacheDashboard models={models} />,
            path: '/cache-dashboard',
        },
    ]);

    return <RouterProvider router={router} />;
};

export default App;
