import React, { useCallback, useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import SingleEntityMultiTimeWidget from 'App/WidgetPanel/Widgets/SingleEntityMultiTimeWidget';
import { WidgetDefinitionWithId } from 'types/inspection-types/WidgetDefinition';
import MultiEntitySingleTimeWidget from 'App/WidgetPanel/Widgets/MultiEntitySingleTimeWidget/MultiEntitySingleTimeWidget';
import { WidgetType } from 'types/inspection-types/WidgetType';
import MultiEntityMultiTimeWidget from 'App/WidgetPanel/Widgets/MultiEntityMultiTimeWidget/MultiEntityMultiTimeWidget';
import { MdDelete } from 'react-icons/md';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { BiCurrentLocation } from 'react-icons/bi';
import WidgetContext from 'App/WidgetContext';
import { darkest, mid } from 'tools/colors';
import { useDrag } from 'react-dnd';
import DNDType from 'types/dnd/DNDType';
import WidgetDragObject from 'types/dnd/WidgetDragObject';
import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import AnnotationWidget from 'App/WidgetPanel/Widgets/AnnotationWidget';
import ScatterplotWidget from 'App/WidgetPanel/Widgets/ScatterplotWidget/ScatterplotWidget';
import LoadingWidget from 'App/WidgetPanel/Widgets/LoadingWidget';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import VerbalizationWidget from 'App/WidgetPanel/Widgets/VerbalizationWidget';
import MultiHistogramWidget from './Widgets/MultiHistogramWidget';
import { Linking } from 'App/LinkingAndBrushing';
import WidgetHeaderButton from 'App/WidgetPanel/WidgetHeaderButton';
import FeaturewiseDistributionWidget from 'App/WidgetPanel/Widgets/FeaturewiseDistributionWidget';
import ClassSelectionContext from 'App/ClassSelectionContext';
import HistogramWidget from 'App/WidgetPanel/Widgets/HistogramWidget';
import { useBrush } from 'tools/hooks/useLinkAndBrush';
import InputOutputClassifierWidget from 'App/WidgetPanel/Widgets/ImageInputOutputWidget/InputOutputClassifierWidget';
import InputOutputAutoencoderWidget from 'App/WidgetPanel/Widgets/ImageInputOutputWidget/InputOutputAutoencoderWidget';
import { getNativeLevelOfAbstraction } from 'types/inspection-types/EntityType';
import ImageDataSampleWidget from 'App/WidgetPanel/Widgets/ImageDataSampleWidget';
import ConfusionMatrixWidget from 'App/WidgetPanel/Widgets/ConfusionMatrixWidget';
import { RiScreenshot2Fill } from 'react-icons/ri';
import html2canvas from 'html2canvas';

const ICON_SIZE = '20px';

const StyledCard = styled(Card)`
    height: 100%;
    width: 100%;
    box-shadow: ${({ $baseColor, $linked }) => ($linked ? `0 0 10px 2px ${darkest($baseColor)}` : 'unset')};
`;

const StyledCardHeader = styled(Card.Header)`
    padding: 6px !important;
    background-color: ${({ $baseColor }) => mid($baseColor)};
`;

const StyledCardBody = styled(Card.Body)`
    flex-grow: 1;

    height: 100%;
    width: 100%;

    padding: 6px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-content: center;

    overflow-y: auto;
`;

const StyledCardFooter = styled(Card.Footer)`
    padding: 6px !important;
    line-height: 17px;
    font-size: 14px;
`;

const WidgetTitleSpan = styled.span`
    font-size: 14px;
`;

const WidgetTargetEntitiesSpan = styled.span`
    font-size: 12px;
`;

export interface WidgetProps {
    widgetDefinition: WidgetDefinitionWithId;
    margin?: { top: number; right: number; bottom: number; left: number };
}

const Widget = ({ widgetDefinition, margin }: WidgetProps) => {
    const { focus, ascendLofa } = useContext(InspectionPanelContext);
    const { removeWidget } = React.useContext(WidgetContext);
    const [hovered, setHovered] = useState<boolean>(false);
    const { selectedClasses, allClasses } = useContext(ClassSelectionContext);

    const [hoveredBrush] = useBrush<string | undefined>(
        'widget-hovered',
        hovered ? widgetDefinition.targetEntity.id : undefined
    );

    const widgetSpecificButtons: JSX.Element[] = [];

    const classSelectorWarning =
        selectedClasses.length < allClasses.length ? <NoClassSelectionAvailableButton /> : null;

    let vis: JSX.Element;
    if (!widgetDefinition.ready) {
        // If entity array is still empty, this widget has no data yet and, therefore, pending promises
        // (that will be resolved by WidgetContextProvider->addWidget() as soon as data is available)
        vis = <LoadingWidget wd={widgetDefinition} />;
    } else {
        switch (widgetDefinition.widgetType) {
            case WidgetType.MULTI_ENTITY_SINGLE_TIME:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <MultiEntitySingleTimeWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.SINGLE_ENTITY_MULTI_TIME:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <SingleEntityMultiTimeWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.MULTI_ENTITY_MULTI_TIME:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <MultiEntityMultiTimeWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.INPUT_OUTPUT_COMPARISON_CLASSIFIER:
                vis = <InputOutputClassifierWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.INPUT_OUTPUT_COMPARISON_AUTOENCODER:
                vis = <InputOutputAutoencoderWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.ANNOTATION:
                vis = <AnnotationWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.SCATTERPLOT_2D:
                vis = <ScatterplotWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.HISTOGRAM:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <HistogramWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.MULTI_HISTOGRAM:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <MultiHistogramWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.FEATURE_HISTOGRAM:
                classSelectorWarning && widgetSpecificButtons.push(classSelectorWarning);
                vis = <FeaturewiseDistributionWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.CONFUSION_MATRIX:
                vis = <ConfusionMatrixWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.IMAGE_DATA_SAMPLES:
                vis = <ImageDataSampleWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            case WidgetType.VERBALIZATION:
                vis = <VerbalizationWidget widgetDefinition={widgetDefinition} margin={margin} />;
                break;
            default:
                vis = <></>;
                break;
        }
    }

    const onGoToHandler = useCallback(
        (e: React.MouseEvent) => {
            const entities = widgetDefinition.dataEntities;

            if (entities.length === 0) {
                return;
            } else if (entities.length === 1) {
                // Go to the deepest level of abstraction to display the exact entity.
                focus(widgetDefinition.dataEntities[0].entity);
            } else {
                // Go to the top-most level of abstraction since groups of entities only occur there.
                // (The parameter does NOT specify the target LofA but rather the delta. Since
                //  LevelOfAbstraction.MULTI_MODEL is the largest LofA we make sure to always ascend to the top.)
                ascendLofa(LevelOfAbstraction.MULTI_MODEL);
            }

            e.stopPropagation();
        },
        [ascendLofa, focus, widgetDefinition.dataEntities]
    );

    const oncloseHandler = useCallback(
        (e: React.MouseEvent) => {
            hoveredBrush(undefined);
            removeWidget(widgetDefinition.widgetId);
            e.stopPropagation();
        },
        [hoveredBrush, removeWidget, widgetDefinition.widgetId]
    );

    const onScreenshotHandler = useCallback(
        (_: React.MouseEvent) => {
            // Since the `ref` of `StyledCard` is already occupied, we use the widget's ID to access its DOM node.
            const widget = document.getElementById(widgetDefinition.widgetId);

            if (!widget) return;

            html2canvas(widget, {
                scale: 3, // Increase export resolution
            }).then((canvas) => {
                const link = document.createElement('a');
                const screenshotURI = canvas.toDataURL('image/png');

                link.download = `${widgetDefinition.widgetId}.png`;
                link.href = screenshotURI;
                link.click();
            });
        },
        [widgetDefinition.widgetId]
    );

    const [{ opacity }, dragRef, dragPreviewRef] = useDrag<WidgetDragObject, void, { opacity: number }>({
        item: {
            type: DNDType.WIDGET,
            widgetId: widgetDefinition.widgetId,
            sourceLofA: getNativeLevelOfAbstraction(widgetDefinition.targetEntity.type),
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.7 : 1,
        }),
    });

    const widgetColor =
        widgetDefinition.dataEntities.length === 1 ? widgetDefinition.dataEntities[0].color : 'var(--gray)';

    return (
        <Linking eventIdentifier={'entity-hovered'} linkedEntity={widgetDefinition.targetEntity.id}>
            {(isLinked) => (
                <StyledCard
                    id={widgetDefinition.widgetId}
                    ref={dragPreviewRef}
                    bg={'light'}
                    text={'dark'}
                    style={opacity}
                    onMouseOver={() => setHovered(true)}
                    onMouseOut={() => setHovered(false)}
                    $baseColor={widgetColor}
                    $linked={isLinked}
                >
                    <StyledCardHeader ref={dragRef} $baseColor={widgetColor}>
                        <div
                            style={{
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    marginBottom: 'auto',
                                    marginLeft: 'auto',
                                }}
                            >
                                {widgetSpecificButtons.map((element, idx) => (
                                    <div key={idx}>{element}</div>
                                ))}
                                <WidgetHeaderButton
                                    baseColor={widgetColor}
                                    onClick={onScreenshotHandler}
                                    description={'Download the widget as PNG.'}
                                >
                                    <RiScreenshot2Fill size={ICON_SIZE} />
                                </WidgetHeaderButton>
                                <WidgetHeaderButton
                                    baseColor={widgetColor}
                                    onClick={onGoToHandler}
                                    description={
                                        'Jump to the level of abstraction and the entity this widget belongs to.'
                                    }
                                >
                                    <BiCurrentLocation size={ICON_SIZE} />
                                </WidgetHeaderButton>
                                <WidgetHeaderButton
                                    onClick={oncloseHandler}
                                    baseColor={widgetColor}
                                    description={'Delete this widget.'}
                                >
                                    <MdDelete size={ICON_SIZE} />
                                </WidgetHeaderButton>
                            </div>
                        </div>
                        <div className={'align-middle'}>
                            <WidgetHeaderButton baseColor={widgetColor} disabled>
                                {React.cloneElement(widgetDefinition.tool.icon, { size: ICON_SIZE })}
                            </WidgetHeaderButton>
                            <WidgetTitleSpan className={'align-middle'}>{widgetDefinition.tool.name}</WidgetTitleSpan>
                        </div>
                    </StyledCardHeader>
                    <StyledCardBody>{vis}</StyledCardBody>
                    <StyledCardFooter>
                        <WidgetTargetEntitiesSpan>{widgetDefinition.targetEntity.name}</WidgetTargetEntitiesSpan>
                    </StyledCardFooter>
                </StyledCard>
            )}
        </Linking>
    );
};

const NoClassSelectionAvailableButton: React.FunctionComponent = () => (
    <WidgetHeaderButton
        baseColor={'red'}
        description={'The class selector does not have effects on this widget; it always shows data for all classes!'}
        disabled={true}
    >
        <BsFillExclamationTriangleFill size={ICON_SIZE} />
    </WidgetHeaderButton>
);

export default React.memo(Widget);
