import { InterestingnessDescriptor } from 'types/inspection-types/InterestingnessDescriptor';
import { EntityType } from 'types/inspection-types/EntityType';

export interface Entity {
    id: string;
    name: string;
    interestingness: InterestingnessDescriptor[];
    type: EntityType;
}

const ENTITY_INSTANCE = {
    id: '',
    name: '',
};

// Type-guard for an array of models
export function isEntity(maybeEntity: unknown): maybeEntity is Entity {
    if (maybeEntity === null || maybeEntity === undefined) {
        return false;
    }

    if (!(typeof maybeEntity === 'object')) {
        return false;
    }

    const hasProperties = Object.keys(ENTITY_INSTANCE).map(
        (key: string) => (maybeEntity as any)[key] !== undefined // eslint-disable-line @typescript-eslint/no-explicit-any
    );

    return !hasProperties.some((p) => !p);
}
