import React, { Fragment, FunctionComponent, LegacyRef, useCallback, useContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import InspectionPanelContext from 'App/InspectionPanel/InspectionPanelContext';
import L3TreeComponent from 'App/InspectionPanel/L3TreeComponent/L3TreeComponent';
import { Model } from 'types/nn-types/Model';
import { LevelOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import L2ArchitectureComponent from 'App/InspectionPanel/L2ArchitectureComponent/L2ArchitectureComponent';
import { Zoom } from '@visx/zoom';
import styled from 'styled-components';
import ToolContext from 'App/ToolContext';
import L1LayerUnitComponent from 'App/InspectionPanel/L1LayerUnitComponent/L1LayerUnitComponent';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';
import { isCommandKeyPressedOnMacOS } from 'tools/helpers';
import Minimap from './Minimap';
import { ZoomState } from 'types/ZoomState';
import ModelContextProvider from 'App/ModelContextProvider';
import { useMeasure } from 'react-use';
import { ProvidedZoom } from '@visx/zoom/lib/types';
import { Group } from 'types/nn-types/Group';
import GroupContextProvider from 'App/GroupContextProvider';
import GroupsContext from 'App/GroupsContext';
import ZoomStateContextProvider from 'App/InspectionPanel/ZoomStateContextProvider';

const StyledSVG = styled.svg`
    flex-grow: 1;
    background: white;
    touch-action: none;
`;

const VIS_PADDING = 100;

const center = (
    zoom: ProvidedZoom<SVGSVGElement> & ZoomState,
    contentBBox: DOMRect,
    contentWidth: number,
    contentHeight: number
) => {
    const scaleX = contentWidth / (contentBBox.width + VIS_PADDING * 2);
    const scaleY = contentHeight / (contentBBox.height + VIS_PADDING * 2);
    const scale = Math.min(scaleX, scaleY);

    const centeredTransformMatrix = {
        scaleX: scale,
        scaleY: scale,
        translateX: contentWidth / 2 - (contentBBox.x + contentBBox.width / 2) * scale,
        translateY: contentHeight / 2 - (contentBBox.y + contentBBox.height / 2) * scale,
        skewX: 0,
        skewY: 0,
    };

    zoom.setTransformMatrix(centeredTransformMatrix);
};

const INITIAL_TRANSFORM = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0,
};

const SCALE_PARAMS = {
    scaleXMin: 1 / 16,
    scaleXMax: 8,
    scaleYMin: 1 / 16,
    scaleYMax: 8,
};

interface Props {}

const InspectionSVG: FunctionComponent<Props> = ({}) => {
    const { groups } = useContext(GroupsContext);

    const { lofa, getCurrentlySelectedEntityID, ascendLofa } = React.useContext(InspectionPanelContext);

    const { activeTool } = React.useContext(ToolContext);
    const [inspectionContentBBox, setInspectionContentBBox] = React.useState<DOMRect>(new DOMRect());
    const [inspectionContentRef, setInspectionContentRef] = React.useState<SVGGraphicsElement | null>(null);

    const zoomRef = React.useRef<(ProvidedZoom<SVGSVGElement> & ZoomState) | null>(null);
    const [contentDiv, { width: contentWidth, height: contentHeight }] = useMeasure();
    const [contentSizeExpanded, setContentSizeExpanded] = React.useState(false);

    const focusedEntityId = getCurrentlySelectedEntityID();

    // This callback is executed by children after they are done layouting themselves.
    const contentReadyHandler = useCallback(() => {
        inspectionContentRef && setInspectionContentBBox(inspectionContentRef.getBBox());
    }, [inspectionContentRef]);

    // This effect only sets contentSizeExpanded after the vis container's final size is known.
    // Only then can the view be correctly centered.
    React.useEffect(() => {
        if (contentWidth > 0 && contentHeight > 0 && !contentSizeExpanded) {
            setContentSizeExpanded(true);
        }
    }, [contentWidth, contentHeight, contentSizeExpanded, setContentSizeExpanded]);

    // Center content after first load and layer switches.
    React.useEffect(() => {
        zoomRef.current &&
            inspectionContentBBox &&
            center(zoomRef.current, inspectionContentBBox, contentWidth, contentHeight);
    }, [inspectionContentBBox, contentSizeExpanded, contentWidth, contentHeight]);

    const vis: JSX.Element = React.useMemo(() => {
        let group: Group | undefined = undefined;
        let model: Model | undefined = undefined;
        let modelGraphNode: ModelGraphNode | undefined = undefined;

        if (lofa === LevelOfAbstraction.LAYERS_UNITS) {
            // Find the group which contains the model which contains the selected
            // LayerGraphNode entity ID in its LayerGraph.
            groups.forEach((g) => {
                g.models.forEach((m) => {
                    m.graph.nodes.forEach((l) => {
                        if (l.id === focusedEntityId) {
                            group = g;
                            model = m;
                            modelGraphNode = l;
                        }
                    });
                });
            });

            if (group && model && modelGraphNode) {
                return (
                    <GroupContextProvider group={group}>
                        <ModelContextProvider model={model}>
                            <L1LayerUnitComponent onReady={contentReadyHandler} modelGraphNode={modelGraphNode} />
                        </ModelContextProvider>
                    </GroupContextProvider>
                );
            } else {
                return <></>;
            }
        } else if (lofa === LevelOfAbstraction.SINGLE_MODEL) {
            // Find the group which contains the model with the focused entity id.
            groups.forEach((g) => {
                g.models.forEach((m) => {
                    if (m.id === focusedEntityId) {
                        group = g;
                        model = m;
                    }
                });
            });

            if (group && model) {
                return (
                    <GroupContextProvider group={group}>
                        <ModelContextProvider model={model}>
                            <L2ArchitectureComponent onReady={contentReadyHandler} />
                        </ModelContextProvider>
                    </GroupContextProvider>
                );
            } else {
                return <></>;
            }
        } else {
            return <L3TreeComponent onReady={contentReadyHandler} />;
        }
    }, [lofa, groups, contentReadyHandler, focusedEntityId]);

    const onDoubleClickHandler = (e: React.MouseEvent) => {
        if (e.button === 0) {
            if (!e.ctrlKey && !isCommandKeyPressedOnMacOS(e)) {
                ascendLofa();
            }
        }

        e.stopPropagation();
    };

    const iconAsString: string = activeTool
        ? ReactDOMServer.renderToString(React.cloneElement(activeTool?.icon, { size: '32px' }))
        : '';
    const cursor: React.CSSProperties['cursor'] = activeTool
        ? `url(data:image/svg+xml,${encodeURI(iconAsString)}) 16 16, auto`
        : 'default';

    return (
        <div ref={contentDiv as unknown as LegacyRef<HTMLDivElement>} style={{ display: 'flex', flexGrow: 1 }}>
            <Zoom<SVGSVGElement>
                width={contentWidth}
                height={contentHeight}
                {...SCALE_PARAMS}
                initialTransformMatrix={INITIAL_TRANSFORM}
            >
                {(zoom) => {
                    zoomRef.current = zoom;

                    return (
                        <ZoomStateContextProvider zoom={zoom}>
                            <StyledSVG
                                width={contentWidth}
                                height={contentHeight}
                                onTouchStart={zoom.dragStart}
                                onTouchMove={zoom.dragMove}
                                onTouchEnd={zoom.dragEnd}
                                onMouseDown={zoom.dragStart}
                                onMouseMove={zoom.dragMove}
                                onMouseUp={zoom.dragEnd}
                                onDoubleClick={onDoubleClickHandler}
                                style={{
                                    cursor,
                                }}
                                className={'no-select'}
                                ref={zoom.containerRef}
                            >
                                <g style={{ visibility: 'hidden' }}>
                                    <rect
                                        fill={'rgba(0, 0, 0, 0.1)'}
                                        height={contentHeight}
                                        id="viewport"
                                        width={contentWidth}
                                    />
                                </g>
                                <g transform={zoom.toString()}>
                                    <g ref={(r) => setInspectionContentRef(r)} id="inspection-contents">
                                        {vis}
                                    </g>
                                </g>
                            </StyledSVG>
                            <Minimap
                                contentsBoundingBox={inspectionContentBBox}
                                zoom={zoom}
                                zoomString={zoomRef.current?.toString()}
                            />
                        </ZoomStateContextProvider>
                    );
                }}
            </Zoom>
        </div>
    );
};

export default InspectionSVG;
