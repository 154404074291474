import React, { useContext, useMemo, useRef } from 'react';
import { InspectionLayerProps } from 'types/inspection-types/InspectionLayerProps';
import GroupComponent from 'App/InspectionPanel/L3TreeComponent/GroupComponent/GroupComponent';
import { produce } from 'immer';
import sha256 from 'crypto-js/sha256';
import GroupContextProvider from 'App/GroupContextProvider';
import { Group } from 'types/nn-types/Group';
import GroupsContext from 'App/GroupsContext';

function toChecksum(groups: Group[]): string {
    return sha256(
        groups
            .map((g) => g.id)
            .sort()
            .join('_')
    ).toString();
}

interface Props extends InspectionLayerProps {}

const L3TreeComponent = ({ onReady }: Props) => {
    const { groups } = useContext(GroupsContext);

    const [groupDimensions, setGroupDimensions] = React.useState<Record<string, { width: number; height: number }>>({});

    const prevConnectedComponentsChecksum = useRef(toChecksum(groups));
    const connectedComponentsChecksum = toChecksum(groups);

    // If groups change (e.g., new models coming in), delete the outdated entries in groupDimensions
    if (prevConnectedComponentsChecksum.current !== connectedComponentsChecksum) {
        prevConnectedComponentsChecksum.current = connectedComponentsChecksum;

        // Find old entries in groupDimensions (i.e., those, which are still in groupDimensions but not
        // anymore in connectedComponents) and delete them
        setGroupDimensions((prevState) =>
            produce(prevState, (draftState) => {
                const groupIds = groups.map((g) => g.id);

                Object.keys(draftState).forEach((gId) => {
                    if (!groupIds.includes(gId)) {
                        delete draftState[gId];
                    }
                });
            })
        );
    }

    // Memoize those handlers, so they do not lead to infinite re-renders
    // when doing the iterative bottom-up size estimation.
    const groupSizeChangeHandlers = useMemo(
        () =>
            groups.map((g, idx) => (newWidth: number, newHeight: number) => {
                // Pass function to setState, forcing atomic operation to prevent race condition, forcing atomic operation to prevent race condition. See: https://stackoverflow.com/a/30341560
                setGroupDimensions((prevState) =>
                    produce(prevState, (draftState) => {
                        draftState[g.id] = {
                            width: newWidth,
                            height: newHeight,
                        };

                        return draftState;
                    })
                );
            }),
        [groups, setGroupDimensions]
    );

    const groupElements = useMemo(
        () =>
            groups.map((g, idx) => {
                // Sum up the y-offset of all previous groups
                let currentOffset = 0;
                for (let i = 0; i < idx; i++) {
                    const prevGId = groups[i].id;
                    currentOffset += groupDimensions[prevGId] ? groupDimensions[prevGId].height : 0;
                    // Magic number: 2 * Offset of convex hull = 100, offset of model groups = 50
                    currentOffset += 150;
                }

                return (
                    <g key={g.id} transform={`translate(0 ${currentOffset})`}>
                        <GroupContextProvider group={g}>
                            <GroupComponent onSizeChange={groupSizeChangeHandlers[idx]} />
                        </GroupContextProvider>
                    </g>
                );
            }),
        [groups, groupSizeChangeHandlers, groupDimensions]
    );

    // After rendering, notify parent that this layer now has its final size
    React.useEffect(() => {
        // This lofa's visual representation is considered complete, if all model's dimensions are known and the
        // layout algorithm was executed on them. => Notify parent component.
        if (Object.keys(groupDimensions).length === Object.keys(groupDimensions).length) {
            onReady();
        }
    }, [onReady, groupDimensions]);

    return <g className="model-tree">{groupElements}</g>;
};

export default L3TreeComponent;
