import { TransformSpec } from 'types/inspection-types/TransformSpec';

export function generateFilteredNodesTransformSpec(
    classes: Array<string | number>,
    filteredNodeIDs: number[]
): TransformSpec {
    return [
        {
            type: 'apply',
            operation: 'mean',
            attributes: classes,
            as: 'mean',
        },
        {
            type: 'copy',
            attribute: 'mean',
            as: 'meanSummedScalar',
        },
        {
            type: 'reduce',
            attribute: 'meanSummedScalar',
            operation: 'sum',
        },
        {
            type: 'sort',
            attribute: 'meanSummedScalar',
            ascending: false,
        },
        {
            type: 'branchAndMerge',
            transforms: [
                {
                    type: 'head',
                    count: 10,
                },
                {
                    type: 'filter',
                    query: `id in [${filteredNodeIDs.join(', ')}]`,
                },
                {
                    type: 'tail',
                    count: 10,
                },
            ],
        },
        {
            type: 'sort',
            attribute: 'meanSummedScalar',
            ascending: false,
        },
    ];
}

export function generateScalarValuesTransformSpec(classes: Array<string | number>): TransformSpec {
    const transformspec: TransformSpec = [
        {
            type: 'apply',
            operation: 'mean',
            attributes: classes,
            as: 'meanSummedScalar',
        },
        {
            type: 'reduce',
            attribute: 'meanSummedScalar',
            operation: 'sum',
        },
        {
            type: 'sort',
            attribute: 'meanSummedScalar',
            ascending: false,
        },
        {
            type: 'delete',
            attribute: 'meanSummedScalar',
        },
    ];

    // Reduce values of single classes to their sum (only affects multi-dimensional (e.g., conv2d) results)
    classes.forEach((c) =>
        transformspec.push({
            type: 'reduce',
            attribute: c,
            operation: 'sum',
        })
    );

    return transformspec;
}
