// This class defines a derived version of streamich/react-use's useLocalStorage
// where the initialState is obligatory. This eliminates the need to handle 'undefined'
// in the calling code.

import { useLocalStorage as useLocalStorageOrig } from 'react-use';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

const isSetStateFunction = <T>(maybeFunction: T | ((prevState: T) => T)): maybeFunction is (prevState: T) => T =>
    typeof maybeFunction === 'function';

export default function useLocalStorage<T>(key: string, initialState: T): [T, Dispatch<SetStateAction<T>>, () => void] {
    const [maybeValue, setMaybeValue, removeValue] = useLocalStorageOrig<T>(key);

    const value: T = useMemo(() => (maybeValue !== undefined ? maybeValue : initialState), [maybeValue, initialState]);
    const setValue = useCallback(
        (valueOrFunction: T | ((prevState: T) => T)) => {
            if (isSetStateFunction(valueOrFunction)) {
                setMaybeValue((prevStateOrig: T | undefined) => {
                    return valueOrFunction(prevStateOrig !== undefined ? prevStateOrig : initialState);
                });
            } else {
                setMaybeValue(valueOrFunction);
            }
        },
        [initialState, setMaybeValue]
    );

    return [value, setValue, removeValue];
}
