import React from 'react';
import './index.css';
import App from 'App/App';
import 'bootswatch/dist/cosmo/bootstrap.css';
import 'styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Development mode detected; enabling @welldone-software/why-did-you-render.');
    const whyDidYouRender = require('@welldone-software/why-did-you-render'); // eslint-disable-line @typescript-eslint/no-var-requires
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        exclude: [/^RichMarkdownEditor/],
    });
}

const container = document.getElementById('root');

if (container !== null) {
    const root = createRoot(container);
    root.render(<App />);
}
