import React, { FunctionComponent, memo, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ModelGraphNode } from 'types/nn-types/ModelGraph';
import FilterContext from 'App/FilterContext';
import LayerLens from 'App/InspectionPanel/L3TreeComponent/ModelComponent/LayerLens';
import ToolContext from 'App/ToolContext';
import { Tool } from 'types/inspection-types/Tool';
import { greyStroke, highlightColor } from 'styles/colors';
import WidgetContext from 'App/WidgetContext';
import { mid } from 'tools/colors';
import { WidgetType } from 'types/inspection-types/WidgetType';
import addWidget from 'App/InspectionPanel/L2ArchitectureComponent/LayerComponent/add-widget';
import ModelContext from 'App/ModelContext';
import { useInterestingnessSVGFilter } from 'tools/hooks/useSVGFilter';
import InterestingnessContext from 'App/InterestingnessContext';
import GroupContext from 'App/GroupContext';
import { useBrush, useLink } from 'tools/hooks/useLinkAndBrush';

const FilledIcon = styled.g`
    path[fill='none'] {
        fill: white;
    }
`;

const LayerRect = styled.rect<{ linked: boolean }>`
    stroke-width: ${(styles) => (styles.linked ? 3 : 1)}px;
    fill: #f8f8f8;
    stroke: #5f5f5f;
`;

interface Props {
    modelGraphNode: ModelGraphNode;
    x: number;
    y: number;
    width: number;
    height: number;
}

const ArchitectureGraphLayer: FunctionComponent<Props> = (props: Props) => {
    const { group } = useContext(GroupContext);
    const { model } = useContext(ModelContext);
    const { activeTool } = React.useContext(ToolContext);
    const { addWidget: addWidgetCb, getAssociatedWidgets } = useContext(WidgetContext);
    const { containsFilteredElements } = useContext(FilterContext);
    const [hovered, setHovered] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = React.useState<{ x: number; y: number } | null>(null);
    const { getInterestingness, getMaxInnerInterestingness } = useContext(InterestingnessContext);

    useBrush<string | undefined>('entity-hovered', hovered ? props.modelGraphNode.id : undefined);
    const [isLinked] = useLink<string | undefined>('widget-hovered', (brushedEntityIds) => {
        return brushedEntityIds ? brushedEntityIds.includes(props.modelGraphNode.id) : false;
    });

    const annotationWidget = getAssociatedWidgets(props.modelGraphNode.id).filter((w) =>
        [WidgetType.ANNOTATION].includes(w.widgetType)
    );

    const isFiltered = useMemo(
        () => containsFilteredElements(props.modelGraphNode),
        [containsFilteredElements, props.modelGraphNode]
    );

    const onClickHandler = (e: React.MouseEvent) => {
        if (e.button === 0) {
            if (activeTool?.isApplicable(props.modelGraphNode.type)) {
                addWidget(model, props.modelGraphNode, activeTool, addWidgetCb);
            }
        }

        e.stopPropagation();
    };

    const onMouseMoveOver = (e: React.MouseEvent<SVGElement>) => {
        const { clientX: x, clientY: y } = e;
        setHovered(true);
        setTooltipPosition({ x, y });
        e.stopPropagation();
        e.preventDefault();
    };

    const onMouseOutHandler = () => {
        setHovered(false);
        setTooltipPosition(null);
    };

    const maxInterestingness = getMaxInnerInterestingness(group);
    const layerInterestingness = getInterestingness(props.modelGraphNode);
    let interestingness;
    if (maxInterestingness && layerInterestingness) {
        interestingness = layerInterestingness / maxInterestingness;
    }
    const [interestingnessFilterId, interestingnessFilterElement] = useInterestingnessSVGFilter(interestingness ?? 0);

    return (
        <>
            {interestingnessFilterElement}
            <LayerRect
                className={
                    activeTool?.isApplicable(props.modelGraphNode.type) || isLinked ? 'clickable-svg-element' : ''
                }
                filter={interestingness ? `url(#${interestingnessFilterId})` : undefined}
                linked={isLinked}
                onClick={onClickHandler}
                onMouseOver={onMouseMoveOver}
                onMouseOut={onMouseOutHandler}
                rx={2}
                x={props.x - props.width / 2}
                y={props.y - props.height / 2}
                width={props.width}
                height={props.height}
                style={{
                    fill: isFiltered ? highlightColor : 'white',
                }}
            />
            <>
                {annotationWidget?.length > 0 ? (
                    <FilledIcon
                        style={{ color: mid(greyStroke) }}
                        transform={`translate(${props.x + props.width / 2 - 5}, ${props.y - props.height / 2 - 10})`}
                    >
                        {annotationWidget[0].tool.icon}
                    </FilledIcon>
                ) : null}
            </>
            {activeTool?.id === Tool.LAYER_LENS.id && tooltipPosition && (
                <LayerLens x={tooltipPosition.x} y={tooltipPosition.y} hoveredLayerNode={props.modelGraphNode} />
            )}
        </>
    );
};

export default memo(ArchitectureGraphLayer);
