import React from 'react';
import styled from 'styled-components';
import { Text } from '@visx/text';
import { abs } from 'mathjs';

const BOUNDING_RECT_STROKE_WIDTH = 1;

const BoundingRect = styled.rect`
    stroke: var(--gray);
    fill: none;
    stroke-width: ${`${BOUNDING_RECT_STROKE_WIDTH}px`};
`;

const FillRect = styled.rect<{ direction: number }>`
    stroke: none;
    fill: var(${(styles) => (styles.direction >= 0 ? '--primary' : '--danger')});
`;

const FillText = styled(Text)`
    dominant-baseline: central;
    text-anchor: middle;
    fill: var(--secondary);
    font-size: ${({ $width }: { $width: number }) => `${$width}px`};
`;

interface Props {
    width: number;
    height: number;
    maxValue: number;
    summedValues: number;
    value: number;
    x?: number;
    y?: number;
}

const ActivationBarComponent: React.FunctionComponent<Props> = ({
    x = 0,
    y = 0,
    width,
    height,
    value,
    maxValue,
    summedValues,
}: Props) => {
    const innerWidth = width - BOUNDING_RECT_STROKE_WIDTH;
    const innerHeight = height - BOUNDING_RECT_STROKE_WIDTH;

    const maxValueHeight = innerHeight * (value / maxValue);
    const summedValuesHeight = innerHeight * (value / summedValues);

    return (
        <>
            <BoundingRect x={x} y={y} width={width} height={height} />

            <g transform={`translate(${BOUNDING_RECT_STROKE_WIDTH / 2} ${BOUNDING_RECT_STROKE_WIDTH / 2})`}>
                <FillRect
                    direction={maxValueHeight}
                    x={x}
                    y={maxValueHeight > 0 ? y + innerHeight - maxValueHeight : y}
                    width={innerWidth / 2}
                    height={abs(maxValueHeight)}
                />
                <FillRect
                    direction={summedValuesHeight}
                    x={x + innerWidth / 2}
                    y={summedValuesHeight > 0 ? y + innerHeight - summedValuesHeight : y}
                    width={innerWidth / 2}
                    height={abs(summedValuesHeight)}
                />

                <FillText
                    scaleToFit={'shrink-only'}
                    angle={270}
                    x={x + innerWidth * 0.25}
                    y={y + innerHeight / 2}
                    $width={innerWidth / 2 - 2}
                >
                    {`${((value / maxValue) * 100).toFixed(0)}%`}
                </FillText>
                <FillText
                    scaleToFit={'shrink-only'}
                    angle={270}
                    x={x + innerWidth * 0.75}
                    y={y + innerHeight / 2}
                    $width={innerWidth / 2 - 2}
                >
                    {`${((value / summedValues) * 100).toFixed(0)}%`}
                </FillText>
            </g>
        </>
    );
};

export default ActivationBarComponent;
