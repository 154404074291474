import { StatisticalDescriptorsCatalog } from 'types/nn-types/StatisticalDescriptors';
import { InterestingnessDescriptor } from 'types/inspection-types/InterestingnessDescriptor';
import { ModelGraph } from 'types/nn-types/ModelGraph';
import { EntityType } from 'types/inspection-types/EntityType';

export function attachInterestingnessToModelGraph(
    catalog: StatisticalDescriptorsCatalog,
    modelGraph: ModelGraph
): ModelGraph {
    modelGraph.nodes.forEach((layerNode) => {
        if (catalog[layerNode.name]['activation']) {
            layerNode.interestingness = InterestingnessDescriptor.fromStatisticalDescriptors(
                catalog[layerNode.name]['activation'],
                'activation'
            );
        }

        layerNode.innardGraph.nodes.forEach((innardNode) => {
            if (innardNode.type === EntityType.VARIABLE_DENSE_KERNEL && catalog[layerNode.name]['dense_kernel']) {
                innardNode.interestingness = InterestingnessDescriptor.fromStatisticalDescriptors(
                    catalog[layerNode.name]['dense_kernel'],
                    'dense_kernel'
                );
            }
            if (innardNode.type === EntityType.VARIABLE_DENSE_BIAS && catalog[layerNode.name]['dense_bias']) {
                innardNode.interestingness = InterestingnessDescriptor.fromStatisticalDescriptors(
                    catalog[layerNode.name]['dense_bias'],
                    'dense_bias'
                );
            }
            if (innardNode.type === EntityType.VARIABLE_CONV2D_KERNEL && catalog[layerNode.name]['conv2d_kernel']) {
                innardNode.interestingness = InterestingnessDescriptor.fromStatisticalDescriptors(
                    catalog[layerNode.name]['conv2d_kernel'],
                    'conv2d_kernel'
                );
            }
            if (innardNode.type === EntityType.VARIABLE_CONV2D_BIAS && catalog[layerNode.name]['conv2d_bias']) {
                innardNode.interestingness = InterestingnessDescriptor.fromStatisticalDescriptors(
                    catalog[layerNode.name]['conv2d_bias'],
                    'conv2d_bias'
                );
            }
        });
    });

    return modelGraph;
}
