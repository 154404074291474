import styled from 'styled-components';

const Wrapper = styled.div<{
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    marginTop?: number;
    maxWidth?: number | string;
}>`
    bottom: ${(styles) => (styles.marginBottom !== undefined ? `${styles.marginBottom}px` : 'unset')};
    display: flex;
    left: ${(styles) => (styles.marginLeft !== undefined ? `${styles.marginLeft}px` : 'unset')};
    position: absolute;
    right: ${(styles) => (styles.marginRight !== undefined ? `${styles.marginRight}px` : 'unset')};
    top: ${(styles) => (styles.marginTop !== undefined ? `${styles.marginTop}px` : 'unset')};
    transition: ${(styles) => {
        if (styles.marginLeft !== undefined) {
            return 'left 250ms';
        } else if (styles.marginRight !== undefined) {
            return 'right 250ms';
        } else {
            return 'unset';
        }
    }};
    max-width: ${(styles) =>
        styles.maxWidth !== undefined
            ? typeof styles.maxWidth === 'number'
                ? `${styles.maxWidth}px`
                : styles.maxWidth
            : 'unset'};
`;

export default Wrapper;
