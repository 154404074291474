import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Collapse, Form } from 'react-bootstrap';
import ClassSelectionContext from 'App/ClassSelectionContext';
import Wrapper from 'App/InspectionPanel/Wrapper';
import CardFlap, { Side } from 'App/CardFlap';
import { MdLabelOutline } from 'react-icons/md';

const StyledFormCheck = styled(Form.Check)`
    ${({ $selectionEmpty }) =>
        $selectionEmpty &&
        `
      & > input[type="checkbox"] + label:before {
        background-color: var(--primary-transparent);
      }
      & > input[type="checkbox"] + label:after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");;
      }
  `}
`;

interface Props {
    marginTop: number;
}

const ClassSelectorPanel: React.FunctionComponent<Props> = ({ marginTop }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const { allClasses, isSelectionEmpty, toggleClassSelection, isClassSelected } =
        React.useContext(ClassSelectionContext);

    return (
        <Wrapper marginLeft={0} marginTop={marginTop}>
            <Collapse in={!isCollapsed}>
                <Card style={{ boxShadow: '0 0 10px var(--gray)' }}>
                    <Card.Header>Data Class Labels</Card.Header>
                    <Card.Body>
                        <Form>
                            {allClasses.map((cls, idx) => {
                                const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
                                    toggleClassSelection(cls);
                                };

                                return (
                                    <StyledFormCheck
                                        $selectionEmpty={isSelectionEmpty()}
                                        custom
                                        className={'no-select'}
                                        checked={isClassSelected(cls) && !isSelectionEmpty()}
                                        key={idx}
                                        label={cls}
                                        type={'checkbox'}
                                        id={`class-selection-checkbox-${cls}`}
                                        onChange={onChangeHandler}
                                    />
                                );
                            })}
                        </Form>
                    </Card.Body>
                </Card>
            </Collapse>
            <CardFlap
                icon={<MdLabelOutline style={{ marginLeft: 10 }} />}
                isCardCollapsed={isCollapsed}
                setIsCardCollapsed={setIsCollapsed}
                side={Side.RIGHT}
            />
        </Wrapper>
    );
};

export default ClassSelectorPanel;
