import React, { useCallback, useMemo } from 'react';
import SelectionContext from 'App/SelectionContext';
import { produce } from 'immer';
import { Entity, isEntity } from 'types/inspection-types/Entity';

const SelectionContextProvider = ({ children }: { children: React.ReactNode }) => {
    // Lift the value into the parent’s state: https://reactjs.org/docs/context.html#caveats
    const [selectedEntities, setSelectedEntities] = React.useState<string[]>([]);

    const isSelectedMemo = useCallback(
        (entityOrEntityId: Entity | string) => {
            const entityId = isEntity(entityOrEntityId) ? entityOrEntityId.id : entityOrEntityId;
            return selectedEntities.findIndex((id) => id === entityId) >= 0;
        },
        [selectedEntities]
    );

    const selectMemo = useCallback(
        (entityOrEntityId: Entity | string) => {
            const entityId = isEntity(entityOrEntityId) ? entityOrEntityId.id : entityOrEntityId;

            if (!isSelectedMemo(entityOrEntityId)) {
                // Pass function to setState, forcing atomic operation to prevent race condition. See: https://stackoverflow.com/a/30341560
                setSelectedEntities((prevState) =>
                    produce(prevState, (draftState) => {
                        draftState.push(entityId);
                    })
                );
            }
        },
        [isSelectedMemo]
    );

    const unselect = (entityOrEntityId: Entity | string) => {
        const entityId = isEntity(entityOrEntityId) ? entityOrEntityId.id : entityOrEntityId;

        setSelectedEntities((prevState) =>
            produce(prevState, (draftState) => {
                return draftState.filter((id) => id !== entityId);
            })
        );
    };

    const toggleSelection = (entityOrEntityId: Entity | string) =>
        isSelectedMemo(entityOrEntityId) ? unselect(entityOrEntityId) : selectMemo(entityOrEntityId);

    // Memoize the callbacks, so they do not lead to unnecessary re-renders.
    const unselectMemo = useCallback(unselect, []);
    const toggleSelectionMemo = useCallback(toggleSelection, [isSelectedMemo, selectMemo]);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            selectedEntities,
            isSelected: isSelectedMemo,
            select: selectMemo,
            unselect: unselectMemo,
            toggleSelection: toggleSelectionMemo,
        }),
        [isSelectedMemo, selectMemo, selectedEntities, toggleSelectionMemo, unselectMemo]
    );

    return <SelectionContext.Provider value={providerValueMemo}>{children}</SelectionContext.Provider>;
};

export default SelectionContextProvider;
