import React, { useCallback } from 'react';
import { Tool } from 'types/inspection-types/Tool';
import { useTooltipInPortal } from '@visx/tooltip';
import styled from 'styled-components';
import ToolContext from 'App/ToolContext';
import NiceTooltipContent from 'App/NiceTooltipContent';

const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 40px;
    height: 40px;
    padding: 0;

    &:hover {
        background-color: white;
        color: inherit;
    }
`;

interface Props {
    tool: Tool;
}

const ToolButton: React.FunctionComponent<Props> = (props: Props) => {
    const ICON_SIZE = 20;

    const [tooltipPosition, setTooltipPosition] = React.useState<{ x: number; y: number } | null>(null);
    const { activeTool, setActiveTool, unsetActiveTool } = React.useContext(ToolContext);

    const { TooltipInPortal } = useTooltipInPortal({
        // use TooltipWithBounds
        detectBounds: true,
        // when tooltip containers are scrolled, this will correctly update the Tooltip position
        scroll: true,
    });

    const isCurrentlyActive = activeTool?.id == props.tool.id;
    const isImplemented = props.tool.applicableTo.length > 0;

    const onMouseMoveHandler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const { clientX: x, clientY: y } = e;
        setTooltipPosition({ x, y });
    }, []);

    const onMouseOutHandler = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        setTooltipPosition(null);
    }, []);

    const onClickHandler = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            if (isImplemented) {
                if (isCurrentlyActive) {
                    unsetActiveTool();
                } else {
                    setActiveTool(props.tool);
                }
            }
        },
        [isImplemented, isCurrentlyActive, props.tool, setActiveTool, unsetActiveTool]
    );

    return (
        <>
            <StyledButton
                id={`tool-${props.tool.id}`}
                onMouseMove={onMouseMoveHandler}
                onMouseOut={onMouseOutHandler}
                onClick={onClickHandler}
                style={{
                    background: isCurrentlyActive ? 'var(--blue)' : undefined,
                    color: isImplemented ? 'var(--gray-dark)' : 'var(--gray)',
                }}
            >
                {React.cloneElement(props.tool.icon, { size: ICON_SIZE })}
            </StyledButton>

            {tooltipPosition && (
                <TooltipInPortal
                    key={Math.random()} // set this to random so it correctly updates with parent bounds
                    left={tooltipPosition.x}
                    top={tooltipPosition.y}
                    applyPositionStyle={true}
                    unstyled={true}
                >
                    <NiceTooltipContent title={props.tool.name} content={props.tool.description} />
                </TooltipInPortal>
            )}
        </>
    );
};

export default ToolButton;
