import React, { useContext, useMemo } from 'react';
import ConvexHullComponent from 'App/InspectionPanel/ConvexHullComponent';
import { layout } from 'App/InspectionPanel/L3TreeComponent/layout';
import { produce } from 'immer';
import ModelComponent from 'App/InspectionPanel/L3TreeComponent/ModelComponent/ModelComponent';
import EdgeComponent from 'App/InspectionPanel/L3TreeComponent/EdgeComponent/EdgeComponent';
import { isCommandKeyPressedOnMacOS } from 'tools/helpers';
import { EntityType } from 'types/inspection-types/EntityType';
import addWidget from 'App/InspectionPanel/L3TreeComponent/GroupComponent/add-widget';
import ToolContext from 'App/ToolContext';
import WidgetContext from 'App/WidgetContext';
import GroupContext from 'App/GroupContext';
import ModelContextProvider from 'App/ModelContextProvider';

interface Props {
    onSizeChange?: (newWidth: number, newHeight: number) => void;
}

const GroupComponent: React.FunctionComponent<Props> = ({ onSizeChange }: Props) => {
    const { group } = useContext(GroupContext);
    const { addWidget: addWidgetCb } = React.useContext(WidgetContext);
    const { activeTool } = React.useContext(ToolContext);
    const [modelDimensions, setModelDimensions] = React.useState<Record<string, { width: number; height: number }>>({});

    const graph = useMemo(() => layout(group.models, modelDimensions, 50, 100), [group, modelDimensions]);

    // Memoize those handlers, so they do not lead to infinite re-renders
    // when doing the iterative bottom-up size estimation.
    const modelSizeChangeHandlers = useMemo(
        () =>
            group.models.map((model) => {
                return (newWidth: number, newHeight: number) => {
                    // Pass function to setState, forcing atomic operation to prevent race condition.
                    // See: https://stackoverflow.com/a/30341560
                    setModelDimensions((prevState) =>
                        produce(prevState, (draftState) => {
                            draftState[model.id] = {
                                width: newWidth,
                                height: newHeight,
                            };

                            return draftState;
                        })
                    );
                };
            }),
        [group.models, setModelDimensions]
    );

    const modelElements = useMemo(
        () =>
            group.models.map((model, idx) => {
                const node = graph.node(model.id);

                return (
                    <ModelContextProvider key={node.model.id} model={node.model}>
                        <ModelComponent x={node.x} y={node.y} onSizeChange={modelSizeChangeHandlers[idx]} />
                    </ModelContextProvider>
                );
            }),
        [group.models, graph, modelSizeChangeHandlers]
    );

    React.useEffect(() => {
        // Notify parent about size re-calculation
        if (onSizeChange) {
            onSizeChange(graph.graph().width as number, graph.graph().height as number);
        }
    }, [modelDimensions, graph, onSizeChange]);

    const maxParameters = graph.nodes().reduce((acc, n) => {
        return Math.max(acc, graph.node(n).model.stats.numTrainableParameters);
    }, 0);

    const edgeElements = graph.edges().map((e, idx) => {
        const edge = graph.edge(e);

        const srcNode = graph.node(e.v);
        const targetNode = graph.node(e.w);

        return (
            <EdgeComponent
                maxParameters={maxParameters}
                key={idx}
                srcNode={srcNode}
                targetNode={targetNode}
                edge={edge}
            />
        );
    });

    const onClickHandler = (e: React.MouseEvent) => {
        if (e.button === 0) {
            if (!e.ctrlKey && !isCommandKeyPressedOnMacOS(e) && activeTool?.isApplicable(EntityType.TREE_OF_MODELS)) {
                addWidget(group, graph, activeTool, addWidgetCb);
            }
        }

        e.stopPropagation();
    };

    return (
        <>
            <ConvexHullComponent
                id={`l3-group-component-${group.groupIdx}-hull`}
                entity={group}
                graph={graph}
                onClick={onClickHandler}
            />
            {edgeElements}
            {modelElements}
        </>
    );
};

export default GroupComponent;
