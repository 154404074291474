import dagre from 'dagre';
import { Model } from 'types/nn-types/Model';
import { DagreModelNode } from 'types/dagre-nodes/DagreModelNode';

export function layout(
    models: Model[],
    modelDimensions: Record<string, { width: number; height: number }>,
    nodesep: number,
    ranksep: number
) {
    const g = new dagre.graphlib.Graph<DagreModelNode>({ compound: true })
        .setGraph({
            rankdir: 'LR', // Direction for rank nodes. Can be TB, BT, LR, or RL, where T = top, B = bottom, L = left, and R = right.
            nodesep, // Number of pixels that separate edges horizontally in the layout.
            edgesep: nodesep,
            ranksep, // Number of pixels between each rank in the layout.
            marginx: 0,
            marginy: 0,
            ranker: 'tight-tree',
        })
        .setDefaultEdgeLabel(() => ({}));

    // Add nodes to graph
    models.forEach((m) => {
        const mSize = modelDimensions[m.id];

        g.setNode(m.id, {
            model: m,
            width: mSize ? mSize.width : 100,
            height: mSize ? mSize.height : 100,
        });
    });

    // Add edges to graph
    models.forEach((m) => {
        m.info.parents.forEach((p) => {
            // Since parent model might be still missing (e.g., due to promises resolving in non-deterministic order),
            // first check if the parent is available
            if (models.map((m) => m.id).includes(p)) {
                g.setEdge(p, m.id);
            }
        });
    });

    // Layout nodes
    dagre.layout(g);

    return g;
}
