import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const HeaderContainer = styled.div`
    flex: 0 0 auto;
`;

const StyledNavbar = styled(Navbar)`
    box-shadow: 0 0 10px var(--dark);
    z-index: 2000;
`;

const Header = () => {
    return (
        <HeaderContainer>
            <StyledNavbar bg="dark" variant="dark">
                <Navbar.Brand>
                    !<b>NN</b>spector
                </Navbar.Brand>
                <Nav className="mr-auto">
                    <Nav.Link href="#blog">Blog</Nav.Link>
                    <Nav.Link href="#paper">Paper</Nav.Link>
                </Nav>
                {/*<Nav className="mr-auto">*/}
                {/*    <NavDropdown title="Use-Cases" id="nav-use-case-dropdown">*/}
                {/*        <NavDropdown.Item href='?modelIds=["bab98735-d119-433d-a893-08232533973e","0999a51f-5114-471a-8322-9fa40ae60387","c3f60635-72e1-4fc6-9271-0a5eb551eca4","ebda3d72-4ca6-4a4d-97f4-3ceed2ec5e44","07055c13-de13-49a2-8db2-3c446b48ce6d","ea8bb373-c574-4e27-bc69-c900612019e8","e8b63504-3c83-40aa-bc06-0a8a6dcd4184","0a450a9f-8497-4b44-b08a-4134a5816ab1"]'>*/}
                {/*            UC1: MNIST Classifiers*/}
                {/*        </NavDropdown.Item>*/}
                {/*        <NavDropdown.Item href='?modelIds=["a7e13cb0-48c4-4a85-9053-2a9850d72320","2630f097-8b3d-4f07-8673-c5272afe1f6b","68116d1b-bbc2-4693-819d-5c93b902e8b5","45ad4cd7-df1a-4525-9adb-355f0a125f6e","5ce5549e-b980-4ab9-84cb-fdaa378802f4"]'>*/}
                {/*            UC2: Variational Auto-Encoders*/}
                {/*        </NavDropdown.Item>*/}
                {/*        <NavDropdown.Item href='?modelIds=["a7e13cb0-48c4-4a85-9053-2a9850d72320","2630f097-8b3d-4f07-8673-c5272afe1f6b","68116d1b-bbc2-4693-819d-5c93b902e8b5","45ad4cd7-df1a-4525-9adb-355f0a125f6e","5ce5549e-b980-4ab9-84cb-fdaa378802f4","6929c134-6328-4c30-9b66-77694f7107db"]'>*/}
                {/*            UC3: Variational Auto-Encoders Fixed*/}
                {/*        </NavDropdown.Item>*/}
                {/*    </NavDropdown>*/}
                {/*</Nav>*/}
                <Nav className="mr-sm-2">
                    <Nav.Link href="#imprint">Imprint</Nav.Link>
                    <Nav.Link href="#about">About</Nav.Link>
                </Nav>
            </StyledNavbar>
        </HeaderContainer>
    );
};

export default Header;
