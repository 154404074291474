import React, { useCallback, useMemo, useState } from 'react';
import { Bar } from '@visx/shape';
import { scaleBand, scaleLinear } from '@visx/scale';
import _ from 'lodash';
import { useTooltipInPortal } from '@visx/tooltip';

interface Props extends React.SVGProps<SVGSVGElement> {
    data: number[];
    maxValue?: number;
    width?: number;
    height?: number;
    colorScale?: (idx: number) => string;
}

const TinyBarChart: React.FunctionComponent<Props> = ({
    width = 32,
    height = 32,
    maxValue: maybeMaxValue,
    data,
    colorScale,
    ...svgProps
}: Props) => {
    const [tooltipPos, setTooltipPos] = useState<{ x: number; y: number; datapoint: number } | undefined>();
    const maxValue = maybeMaxValue ?? Math.max(...data);

    const xScale = useMemo(
        () =>
            scaleBand<number>({
                range: [0, width],
                round: false,
                domain: _.range(data.length),
                padding: 0,
            }),
        [data.length, width]
    );

    const yScale = useMemo(
        () =>
            scaleLinear<number>({
                range: [0, height],
                round: true,
                domain: [0, maxValue],
            }),
        [height, maxValue]
    );

    const { TooltipInPortal: Tooltip } = useTooltipInPortal();

    const handleMouseMove = useCallback((event: React.MouseEvent, datapoint: number) => {
        setTooltipPos({ x: event.clientX, y: event.clientY, datapoint });
    }, []);

    const handleMouseLeave = useCallback(() => {
        setTooltipPos(undefined);
    }, []);

    return (
        <svg width={width} height={height} {...svgProps}>
            {data.map((d, idx) => {
                const barWidth = xScale.bandwidth();
                const barHeight = yScale(d) ?? 0;
                const barX = xScale(idx) ?? 0;
                const barY = height - barHeight;
                return (
                    <Bar
                        key={`bar-${idx}`}
                        onMouseMove={(e) => handleMouseMove(e, idx)}
                        onMouseLeave={handleMouseLeave}
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        fill={colorScale ? colorScale(idx) : 'var(--gray-dark)'}
                    />
                );
            })}
            {tooltipPos && (
                <Tooltip left={tooltipPos.x} top={tooltipPos.y}>
                    {tooltipPos.datapoint}
                </Tooltip>
            )}
        </svg>
    );
};

export default TinyBarChart;
