import { Card, Form } from 'react-bootstrap';
import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import usePortal from 'react-useportal';
import styled from 'styled-components';
import { scalePow } from 'd3-scale';
import Wrapper from 'App/InspectionPanel/Wrapper';
import { MdSettings } from 'react-icons/md';
import CardFlap, { Side } from 'App/CardFlap';

const SliderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;

    label {
        margin-bottom: unset;
    }
`;

const SpacedRange = styled(Form.Control)`
    margin: 0 15px;
`;

const SLIDER_MIN = 10;
const SLIDER_MAX = 1000;
const E = Math.exp(1);
const SLIDER_SCALE = scalePow().exponent(E).range([SLIDER_MIN, SLIDER_MAX]).domain([SLIDER_MIN, SLIDER_MAX]);

const WeightNetworkSettings = ({
    marginTop,
    numberOfConnections,
    setNumberOfConnections,
}: {
    marginTop: number;
    numberOfConnections: number;
    setNumberOfConnections: Dispatch<SetStateAction<number>>;
}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [marginRight, setMarginRight] = useState<number>(0);
    const [sliderValue, setSliderValue] = React.useState<number>(SLIDER_SCALE.invert(numberOfConnections));

    const { Portal } = usePortal({
        bindTo: document?.getElementById('inspection-panel-container') ?? undefined,
    });

    const scaledValue = Math.round(SLIDER_SCALE(sliderValue) ?? 0);

    const acceptInput = () => setNumberOfConnections(scaledValue);
    const mouseEventCatcher = (e: React.MouseEvent) => e.stopPropagation();

    useEffect(() => setMarginRight(isCollapsed ? -296 : 0), [isCollapsed]);

    return (
        <Portal style={{ border: '3px solid red', height: 200 }}>
            <Wrapper
                marginRight={marginRight}
                marginTop={marginTop}
                onMouseDown={mouseEventCatcher}
                onMouseMove={mouseEventCatcher}
                onMouseUp={mouseEventCatcher}
            >
                <CardFlap
                    icon={<MdSettings style={{ marginLeft: 10 }} />}
                    isCardCollapsed={isCollapsed}
                    setIsCardCollapsed={setIsCollapsed}
                    side={Side.LEFT}
                />
                <Card style={{ boxShadow: '0 0 10px var(--gray)' }}>
                    <Card.Header>Weight Network Settings</Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group controlId="formBasicRange">
                                <Form.Label>
                                    top-<i>k</i> Weights to Display: {scaledValue}
                                </Form.Label>
                                <SliderContainer>
                                    <label>{SLIDER_MIN}</label>
                                    <SpacedRange
                                        min={SLIDER_MIN}
                                        max={SLIDER_MAX}
                                        onChange={(e: FormEvent<HTMLInputElement>) =>
                                            setSliderValue(Number((e.target as HTMLInputElement).value))
                                        }
                                        onMouseUp={acceptInput}
                                        type="range"
                                        value={sliderValue}
                                    />
                                    <label>{SLIDER_MAX}</label>
                                </SliderContainer>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Wrapper>
        </Portal>
    );
};

export default WeightNetworkSettings;
