import {
    InterestingnessDescriptor,
    InterestingnessSubcategory,
    InterestingnessSupercategory,
} from 'types/inspection-types/InterestingnessDescriptor';

export class InterestingnessType {
    constructor(
        public readonly supercategory: InterestingnessSupercategory,
        public readonly subcategory: InterestingnessSubcategory
    ) {}

    public equals(other: InterestingnessType): boolean {
        return this.supercategory === other.supercategory && this.subcategory === other.subcategory;
    }

    public static fromDescriptor(d: InterestingnessDescriptor): InterestingnessType {
        return new InterestingnessType(d.supercategory, d.subcategory);
    }

    public static fromDescriptors(ds: InterestingnessDescriptor[]): InterestingnessType[] {
        return this.unique(ds.map((d) => InterestingnessType.fromDescriptor(d)));
    }

    public static unique(ds: InterestingnessType[]): InterestingnessType[] {
        return ds.reduce((acc: InterestingnessType[], curr: InterestingnessType) => {
            const index = acc.findIndex((accIT) => accIT.equals(curr));

            // Interestingness type is not yet in result. Add it.
            if (index === -1) {
                return [...acc, curr];
            } else {
                return acc;
            }
        }, [] as InterestingnessType[]);
    }
}
