import React, { useCallback, useContext, useEffect, useState } from 'react';
import Wrapper from 'App/InspectionPanel/Wrapper';
import { Accordion, Card } from 'react-bootstrap';
import WidgetContext from 'App/WidgetContext';
import styled from 'styled-components';
import WidgetPanelCell from './WidgetPanelCell';
import { useDragDropManager } from 'react-dnd';
import DNDType from 'types/dnd/DNDType';
import WidgetPanelGroupView from 'App/WidgetPanel/WidgetPanelGroupView';
import { LevelOfAbstraction, LevelsOfAbstraction } from 'types/inspection-types/LevelOfAbstraction';
import { AiOutlineTool } from 'react-icons/ai';
import { MdInsertChartOutlined, MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import { produce } from 'immer';
import NewWidgetGroupDropTarget from 'App/WidgetPanel/NewWidgetGroupDropTarget';
import WidgetDragObject from 'types/dnd/WidgetDragObject';

const RowCardBody = styled(Card.Body)`
    margin: 0 !important;
    padding: 0 !important;
`;

const RowDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    min-height: 80px;
    position: relative;
`;

const StackedAccordion = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > * {
        margin-bottom: -1px;
    }
`;

const AccordionHeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

interface Props {
    marginRight: number;
    marginBottom: number;
}

const EmptyRowHint: React.FunctionComponent = () => {
    return (
        <div
            style={{
                fontSize: '18px',
                flexGrow: 1,
                color: 'var(--gray)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    textAlign: 'center',
                }}
            >
                Empty.
                <br />
                Apply tools <AiOutlineTool /> to create widgets <MdInsertChartOutlined />.
            </div>
        </div>
    );
};

const WidgetPanel: React.FunctionComponent<Props> = ({ marginRight, marginBottom }: Props) => {
    const { widgetTable, useOnWidgetAdded } = useContext(WidgetContext);
    const [activeAccordionKeys, setActiveAccordionKeys] = useState<string[]>([`${LevelOfAbstraction.MULTI_MODEL}`]);
    const [isDragging, setIsDragging] = useState<WidgetDragObject | undefined>();
    const dragDropManager = useDragDropManager();
    const [expandedGroup, setExpandedGroup] = useState<[LevelOfAbstraction, string, string]>();

    const activateAccordionKey = useCallback((lofaStr: string) => {
        setActiveAccordionKeys((prevState) =>
            produce(prevState, (draftState) => {
                const keySet = new Set(draftState);
                keySet.add(lofaStr);
                return [...keySet];
            })
        );
    }, []);

    const toggleAccordionKey = useCallback((lofaStr: string) => {
        setActiveAccordionKeys((prevState) =>
            produce(prevState, (draftState) => {
                const keySet = new Set(draftState);
                if (keySet.has(lofaStr)) {
                    keySet.delete(lofaStr);
                } else {
                    keySet.add(lofaStr);
                }
                return [...keySet];
            })
        );
    }, []);

    // Effect is used to execute callback when a widget is added to WidgetContext. In this case, we want to open
    // the respective page of the accordion.
    useOnWidgetAdded((widgetIdentifier) => activateAccordionKey(`${widgetIdentifier.lofa}`));

    useEffect(() => {
        // Subscribe to dragging state
        dragDropManager.getMonitor().subscribeToStateChange(
            () => {
                setIsDragging(dragDropManager.getMonitor().getItem() as WidgetDragObject);
            },
            { handlerIds: [DNDType.WIDGET] }
        );
    }, [dragDropManager]);

    if (expandedGroup !== undefined) {
        const lofa = expandedGroup[0];
        const groupId = expandedGroup[1];
        const groupTitle = expandedGroup[2];
        const group = widgetTable[lofa].widgetGroups.find((wg) => wg.groupId === groupId);

        if (group !== undefined) {
            return (
                <Wrapper marginRight={marginRight} marginBottom={marginBottom}>
                    <WidgetPanelGroupView
                        title={groupTitle}
                        closeHandler={() => setExpandedGroup(undefined)}
                        widgetDefinitions={group.widgetDefinitions}
                    />
                </Wrapper>
            );
        }
    }

    return (
        <Wrapper marginRight={marginRight} marginBottom={marginBottom} maxWidth={'80%'}>
            <StackedAccordion>
                {LevelsOfAbstraction.map((lofa) => {
                    const { rowName, widgetGroups } = widgetTable[lofa];

                    const key = `${lofa}`;
                    const numGroups = widgetGroups.length;
                    const numWidgets = widgetGroups.reduce((acc, curr) => acc + curr.widgetDefinitions.length, 0);

                    const activeKey = activeAccordionKeys.includes(key) ? key : undefined;

                    return (
                        <Accordion
                            key={key}
                            activeKey={activeKey}
                            className={'no-select'}
                            style={{ transition: '1s all' }}
                        >
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={key}
                                    onClick={() => toggleAccordionKey(key)}
                                >
                                    <AccordionHeaderContent>
                                        <div>
                                            <b>{rowName}:</b>
                                            {'\u00a0\u00a0'}
                                            {numGroups} Group{numGroups !== 1 ? 's' : ''}, {numWidgets} Widget
                                            {numWidgets !== 1 ? 's' : ''}
                                        </div>
                                        <div>
                                            {activeKey !== undefined ? (
                                                <MdOutlineExpandMore size={'2em'} />
                                            ) : (
                                                <MdOutlineExpandLess size={'2em'} />
                                            )}
                                        </div>
                                    </AccordionHeaderContent>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={key}>
                                    <RowCardBody>
                                        <RowDiv>
                                            {widgetGroups.map(({ groupId, widgetDefinitions }, idx) => (
                                                <WidgetPanelCell
                                                    style={
                                                        idx < widgetGroups.length - 1
                                                            ? { borderRight: '1px solid rgba(0, 0, 0, 0.125)' }
                                                            : undefined
                                                    }
                                                    key={groupId}
                                                    groupId={groupId}
                                                    widgetDefinitions={widgetDefinitions}
                                                    openGroupViewCB={() =>
                                                        setExpandedGroup([lofa, groupId, rowName + ' / G' + idx])
                                                    }
                                                />
                                            ))}
                                            {widgetGroups.length === 0 && <EmptyRowHint />}
                                            <NewWidgetGroupDropTarget visible={isDragging?.sourceLofA === lofa} />
                                        </RowDiv>
                                    </RowCardBody>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    );
                })}
            </StackedAccordion>
        </Wrapper>
    );
};

export default React.memo(WidgetPanel);
