import React, { useMemo, useState } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { createEditor, Descendant } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

export const makeInitialEditorContent = (content: string): Descendant[] => [
    {
        children: [{ text: content }],
    },
];

const AnnotationWidget = ({ widgetDefinition }: WidgetProps) => {
    const [editorContent, setEditorContent] = useState<Descendant[]>(
        widgetDefinition.dataEntities[0].data.length > 0
            ? makeInitialEditorContent(widgetDefinition.dataEntities[0].data[0]['text'] as string)
            : makeInitialEditorContent('')
    );

    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    return (
        <Slate
            editor={editor}
            onChange={(newContent) => {
                setEditorContent(newContent);
            }}
            value={editorContent}
        >
            <Editable />
        </Slate>
    );
};

export default AnnotationWidget;
