import React, { useRef } from 'react';
import { WidgetProps } from 'App/WidgetPanel/Widget';
import { ParentSize } from '@visx/responsive';
import { Group } from '@visx/group';
import { AxisBottom, AxisLeft } from '@visx/axis';
import SelectionContext from 'App/SelectionContext';
import { Button } from 'react-bootstrap';
import {
    AttributeSelectionButtonGroup,
    InnerVisContainer,
    LegendContainer,
    OuterVisContainer,
} from 'App/WidgetPanel/Widgets/StyledContainers';
import { getXScale, getYScale } from 'App/WidgetPanel/Widgets/multi-time-scale';
import AxisLabels from 'App/WidgetPanel/Widgets/AxisLabels';
import _ from 'lodash';
import * as uuid from 'uuid';
import DataLine from 'App/WidgetPanel/Widgets/MultiEntityMultiTimeWidget/DataLine';
import useLocalStorage from 'tools/hooks/useLocalStorage';

const DEFAULT_MARGIN = { top: 10, right: 25, bottom: 35, left: 35 };

const MultiEntityMultiTimeWidget = ({ widgetDefinition, margin = DEFAULT_MARGIN }: WidgetProps) => {
    const { selectedEntities: selectedEntitiesRaw } = React.useContext(SelectionContext);
    const clipPathRef = useRef<string>(uuid.v4());

    const { dataEntities } = widgetDefinition;
    // If no entities are selected, all of them should be active (i.e., consider all of them as selected)
    const selectedEntities =
        selectedEntitiesRaw.length === 0 ? dataEntities.map((e) => e.entity.id) : selectedEntitiesRaw;

    const attributes = [
        ...new Set(_.flatten(dataEntities.map((e) => Object.keys(e.data[0]).filter((a) => a !== 'step')))),
    ];

    // Put this into local storage, so it keeps constant over unmount-mount-cycles
    const [selectedAttribute, setSelectedAttribute] = useLocalStorage(
        `widget:${widgetDefinition.widgetId}/selectedAttribute`,
        attributes[0]
    );

    return (
        <>
            <OuterVisContainer>
                <InnerVisContainer>
                    <ParentSize debounceTime={10}>
                        {({ width: visWidth, height: visHeight }) => {
                            const xMax = visWidth - margin.left - margin.right;
                            const yMax = visHeight - margin.top - margin.bottom;
                            const xScale = getXScale(dataEntities, xMax, widgetDefinition.entityRanges);
                            const yScale = getYScale(
                                dataEntities.filter((e) => selectedEntities.includes(e.entity.id)),
                                // If widget has its range defined from outside, use all attributes for scaling
                                widgetDefinition.entityRanges ? attributes : [selectedAttribute],
                                widgetDefinition.scaleCreatorFn,
                                yMax,
                                widgetDefinition.entityRanges
                            );

                            return (
                                <>
                                    <svg width={visWidth} height={visHeight} style={{ background: '#fff' }}>
                                        <Group left={margin.left} top={margin.top}>
                                            <AxisLeft scale={yScale} numTicks={5} />
                                            <AxisBottom top={yMax} scale={xScale} numTicks={5} />

                                            <clipPath id={clipPathRef.current}>
                                                {/* Define a clip path to confine lines to plotting area (in case scale
                                                for current selections makes some lines escape the plotting area) */}
                                                <rect x={0} y={0} width={xMax} height={yMax} fill={'black'} />
                                            </clipPath>

                                            <Group clipPath={`url(#${clipPathRef.current})`}>
                                                {dataEntities.map((e) => {
                                                    const scaledData = e.data.map((d) => [
                                                        xScale(d['step'] as number) ?? 0,
                                                        yScale(d[selectedAttribute] as number) ?? 0,
                                                    ]);
                                                    const color = selectedEntities.find((id) => id === e.entity.id)
                                                        ? e.color
                                                        : 'rgba(200, 200, 200)';

                                                    return (
                                                        <DataLine
                                                            key={e.entity.id}
                                                            entity={e.entity}
                                                            data={scaledData}
                                                            color={color}
                                                        />
                                                    );
                                                })}
                                            </Group>

                                            <AxisLabels
                                                labelX={'Training Step'}
                                                xMax={xMax}
                                                yMax={yMax}
                                                markIncompatibleScales={widgetDefinition.entityRanges === undefined}
                                            />
                                        </Group>
                                    </svg>
                                </>
                            );
                        }}
                    </ParentSize>
                </InnerVisContainer>
            </OuterVisContainer>
            <LegendContainer>
                <AttributeSelectionButtonGroup size="sm">
                    {attributes.map((a) => {
                        const onClickHandler = () => setSelectedAttribute(a);

                        return (
                            <Button
                                key={`button-${a}`}
                                variant={selectedAttribute === a ? 'primary' : 'light'}
                                style={{ color: selectedAttribute === a ? 'var(--light)' : 'var(--gray)' }}
                                onClick={onClickHandler}
                            >
                                {a}
                            </Button>
                        );
                    })}
                </AttributeSelectionButtonGroup>
            </LegendContainer>
        </>
    );
};

export default MultiEntityMultiTimeWidget;
