import React from 'react';
import { EntityType } from 'types/inspection-types/EntityType';
import { Model } from 'types/nn-types/Model';
import { ModelGraphNode, Structure } from 'types/nn-types/ModelGraph';
import { LayerGraphNode } from 'types/nn-types/LayerGraph';

interface IFilterContext {
    filters: EntityType[];
    addFilter: (entityType: EntityType) => void;
    clearFilter: (entityType: EntityType) => void;
    clearAllFilters: () => void;
    containsFilteredElements: (entity: Model | ModelGraphNode | LayerGraphNode) => boolean;
    getEntityTypesMatchedByFilters: (
        entity: Model | ModelGraphNode | LayerGraphNode,
        recursive?: boolean
    ) => EntityType[];
    getStructuresMatchedByFilters: (model: Model) => Structure[];
}

const defaultContext: IFilterContext = {
    filters: [],
    addFilter: () => {
        // Initial value. Replaced by context provider.
    },
    clearFilter: () => {
        // Initial value. Replaced by context provider.
    },
    clearAllFilters: () => {
        // Initial value. Replaced by context provider.
    },
    containsFilteredElements: () => {
        // Initial value. Replaced by context provider.
        return false;
    },
    getEntityTypesMatchedByFilters: () => [],
    getStructuresMatchedByFilters: () => [],
};

const FilterContext = React.createContext(defaultContext);

export default FilterContext;
